<template lang="pug">
  Base(title="Organizations")
    template(#commands)
      b-button-group(v-if="is_admin")
        b-button(variant="outline-primary" @click="editOrg" ) Create Organization
    
    b-container(fluid v-if="orgs")
      b-form-group(label="Filter" :disabled="busy")
        b-form-input(v-model="filter")

      b-table.mt-2#orgTable(small striped hover  :busy="busy" :fields="table_fields" :items="filtered_orgs" @row-clicked="toOrg" sort-by="name" :per-page="perPage" :current-page="currentPage" )

      b-pagination(small pills v-model="currentPage" :total-rows="filtered_orgs.length" :per-page="perPage" aria-controls="orgTable")

    b-modal#orgPanel( @ok="submitSaveOrg" ok-title="Create" title="Create Organization")
      StatusWidget
      b-form(v-if="org")
        b-form-group(label-size="sm" label-cols="3" label="Name" )
          b-form-input(size="sm" v-model="org.name" :disabled="busy")
          

</template>

<script>
import Base from '@/components/Base.vue'
import OrgForm from '@/components/OrgForm.vue'
import StatusWidget from '@/components/StatusWidget.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import baseMix from '@/mixins/base'
import {DateTime} from 'luxon'
import which_role from '@/mixins/which-role'
export default {
  name: 'ManageOrgs',

  mixins: [baseMix, which_role],
  
  components: {
    Base,
    OrgForm,
    StatusWidget
  },
  
  computed: {
    ...mapGetters(['orgs','org']),
    filtered_orgs(){
      if(!this.filter) return this.orgs;
      return this.orgs.filter(o=>{ return o.name.toLowerCase().includes(this.filter.toLowerCase()); });
    }
  },

  data(){
    return {
      filter: "",
      table_fields:[
        { 
          key: 'name',
          sortable: true, 
        },
        { 
          key: 'program_updated', 
          label: 'Program Last Updated',
          sortable: true, 
          formatter: (value)=>{
            let dt = DateTime.fromSQL(value);
            return dt.toLocaleString(DateTime.DATETIME_MED);
          } 
        },
        { 
          key: 'program_status', 
          sortable: true, 
        }
      ],
      currentPage: 1,
      perPage: 10
    };
  },

  mounted(){
    this.load();
  },

  methods: {
    ...mapActions(['searchOrgs','loadOrg','loadPrograms','saveOrg']),
    ...mapMutations(['setOrg']),
    load(){
      this.searchOrgs();
    },
    toOrg(item){
      this.$router.push(`/orgs/${item.id}`);
    },
    editOrg(){
      this.setOrg({
        name: "",
      });
      this.$bvModal.show('orgPanel');
    },
    async submitSaveOrg(evt){
      evt.preventDefault();
      await this.saveOrg(this.org);
      if(!this.has_error){
        setTimeout(()=>{
          this.$bvModal.hide('orgPanel');
          this.searchOrgs();
        }, 3000);
      }
    }
  },
}
</script>
