<template lang="pug">
  b-container(fluid).p-0
    b-card()
      slot(name="title")
        b-card-title
          .d-flex.justify-content-between.m-0(style="font-size: 0.8em")
            span 
              b-button(pill size="sm" variant="light" @click="$router.go(-1)" v-if="with_nav")
                b-icon-arrow-left-short
              span.ml-3 {{title}}
            StatusWidget
            span
              slot(name="commands")
                
      b-card-text
        slot

      template(#footer)
        slot(name="footer")
          b-button.float-right(size="sm" href="mailto:cord@aoassn.org?subject=ORIN Feedback" target="_blank") 
            b-icon-question-circle
            span.ml-2 Contact Us with Questions or Feedback
</template>

<script>
import {mapGetters} from 'vuex'
import StatusWidget from '@/components/StatusWidget.vue'
export default {
  name: 'Base',
  components: { StatusWidget },
  props:{
    title: String,
    with_nav: Boolean,
    with_commands: Boolean
  },

  data(){
    return {};
  },

  computed: {
    ...mapGetters(['message','error','busy','has_error','has_message',])
  },
}
</script>
