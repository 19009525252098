<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.font9.border.rounded-lg.p-2(v-if="!editing" )
    div.d-flex.justify-content-between(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" @click="toggleEdit" )
      div.text-primary {{ label }}
      div {{ entity[property_name] }}

  b-form-group.m-0.p-1(:label-cols="label_cols" v-if="editing").bg-light
    template(#label)
      div.font8(@click="toggleEdit" style="cursor: pointer;") {{ label }}
    b-form-datepicker(size="sm" v-model="entity[property_name]" :class="validate(property_name)" @update="onUpdate" right)
    small.invalid-feedback {{validationMessage(property_name)}}
    small(v-if="hint") {{hint}}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import entity_widget from '@/mixins/entity-widget'
export default {
  name: 'EntityDateField',
  
  props:{
    hint: {type: String}
  },
  
  mixins:[
    entity_widget
  ],

}
</script>
<style scoped>

</style>