<template lang="pug">
b-container.text-dark(fluid).p-0
  div(v-if="metric.display_type==='paragraph'")
    b-form-row
      .col
        b-link(href="#" @click="confirmDelete" :disabled="busy" v-if="!confirming")
          b-badge(variant="danger" size="sm" )
            b-icon-x-circle.
            span.ml-1 delete
        b-link(href="#" @click="submitDelete" :disabled="busy" v-if="confirming" )
          b-badge(variant="danger" size="sm" )
            b-icon-x-circle.
            span.ml-1 confirm you want to delete
      .col
        b-link(href="#" @click="submitSave" :disabled="busy" v-if="changed")
          b-badge(variant="success" size="sm")
            b-icon-plus
            span.ml-1 save
    
    b-form-textarea(size="sm" rows="3" max-rows="5" v-model="program_metric.value" debounce="200" @update="markChanged" :disabled="busy")
    
  b-form-row(v-else)
    .col-1
      b-link.text-danger(href="#" @click="submitDelete" :disabled="busy")
        b-icon-x-circle.mt-1
    .col
      b-form-input(size="sm" :type="metric_input_type" v-model="program_metric.value" debounce="200" @update="markChanged" :disabled="busy")
    b-col(cols="3" v-if="changed")
      b-link(href="#" @click="submitSave" :disabled="busy")
        span.text-success save
        
 
</template>

<script>
import ssvalidation from '@/mixins/ss-validation'
import {mapGetters} from 'vuex'
export default {
  name: 'ProgramMetricInput',
  
  components:{},
  
  mixins:[
    ssvalidation
  ],

  props:{
    program_metric: {type: Object, required: true},
    metric: {type: Object, required: true},
  },


  data(){
    return {
      confirming: false,
      changed: false,
    };
  },

  computed: {
    ...mapGetters(['busy','org','program','has_error']),
    metric_input_type(){
      if(!this.metric || !this.metric.data_type) return "text";
      if(this.metric.data_type==="integer") return "number";
      return "text";
    },
  },

  methods: {
    confirmDelete(){
      this.confirming = true;
      setTimeout(()=>{ this.confirming = false; },5000)
    },
    markChanged(){
      this.changed = true;
    },

    async submitSave(){
      let payload = this.program_metric;
      payload.org_id = this.org.id;
      await this.$store.dispatch("saveProgramMetric", payload);
      if(!this.has_error){
        this.$emit("pm-saved");
        this.changed = false;
      }
    },

    async submitDelete(){
      //assumption is VUEX org and program are available in enclosing view
      let payload = {
        org_id: this.org.id, 
        program_id: this.program.id, 
        id: this.program_metric.id
      };
      await this.$store.dispatch("deleteProgramMetric", payload);
      if(!this.has_error){
        this.changed = false;
        this.$emit("pm-removed");
      }
    }

  },


}
</script>
<style scoped>
input, input[type=number], textarea {
  font-size: .8em;
}
</style>