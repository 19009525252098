/**
 * JWT-enabled auth mixin. Typically used on a root application instance.
 * 
 * Note, dependencies include:
 * - jwt-decode
 * - vue-offline
 * - axios
 */
import jwtdecode from 'jwt-decode'
import {callApi, ApiError, ApiNotAvailableError} from '../lib/api';
import {mapGetters, mapMutations} from 'vuex'
export default {
  data() {
    return {
      username: null,
      password: null,
      email: null,
      jwt: null,
    };
  },

  computed: {
    isLoggedIn(){
      if(!this.jwt) return false;
      try{
        let parsed = jwtdecode(this.jwt);
        let now = Math.floor(Date.now()/1000);
        return (parsed.exp > now);
      } catch(err) {
        return false;
      }
    },
    ...mapGetters(['auth_error_count']),
  },

  watch:{
    /** 
     * Watches overall auth error count (in vuex store). If greater than threshold, forces a logout.
     */
    auth_error_count(current_count){
      const ERR_COUNT_THRESHOLD = 1;
      if( current_count > ERR_COUNT_THRESHOLD ){
        this.clearMessageAndErrors();
        this.logout();
      } else if (current_count > 0){
        this.setGlobalError("Your session expired. Please login again.");
      }
    }
  },

  
  methods: {
    ...mapMutations(['clearMessageAndErrors','decrementInProcess','incrementInProcess','setAuthErrorCount','setJwt','setMessage','setError','setGlobalError','setUser','setOrg']),
    loadAuthState(){
      let t = this.$offlineStorage.get('jwt');
      if(t){
        this.parseToken(t);
      }
    },

    async login(){

      try{
        this.clearMessageAndErrors();
        this.incrementInProcess();
        this.setMessage("Logging in...");

        if(!this.username || !this.password){
          this.clearMessageAndErrors();
          this.setError("Credentials not provided.");
          return;
        }

        let loginResponse = await callApi(null, {method: 'POST', url: `/login`, body: {username: this.username, password: this.password}});

        this.clearMessageAndErrors();
          
        this.jwt = loginResponse.token;

        if(loginResponse.token){
          
          this.parseToken(loginResponse.token);

          if( this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("admin") ){
            this.$router.push({name:'ManageOrgs'});
          
          } else if ( this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("director") ){
            this.$router.push({name:'ViewOrg', params:{org_id: this.$store.state.org.id }});

          } else if ( this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("bi") ){
            this.$router.push({name:'QueryBrowser', params:{org_id: this.$store.state.org.id }});
          }

        } else {
          this.setError("Invalid credentials.");
          return;
        }
      }catch(ex){
        this.clearMessageAndErrors();
        if (ex instanceof ApiNotAvailableError) {
          this.setGlobalError(ex.message);
        } else {
          this.setError(ex.message || "Invalid credentials.");
        }
      } finally {
        this.password = null;
        this.decrementInProcess();
      }

    },

    getAuthTokenParm(){
      let url = new URL(window.location);
      return url.searchParams.get("token");
    },

    /** no validation performed, only parses token, saves to offline storage, and sets related objects. */
    parseToken(t){
      let parsed = jwtdecode(t);//throws error if invalid
      //Set the jwt itself.
      this.jwt = t;

      this.setJwt(t);
      this.$offlineStorage.set('jwt', t);
      
      this.setUser(parsed.user);
      this.$offlineStorage.set('user', parsed.user);
      
      if(parsed.org){
        this.setOrg(parsed.org);
        this.$offlineStorage.set('org', parsed.org);
      }
      
    },
    
    logout(){
      let keys = this.$offlineStorage.keys;
      if(keys){ JSON.parse(this.$offlineStorage.keys).forEach(k => {this.$offlineStorage.set(k, null);}); }
      this.setJwt(null);
      this.setUser(null);
      this.setAuthErrorCount(0);
      this.clearMessageAndErrors();

      //Clear out other app-specific stuff here...
      if(this.$router.currentRoute.path !== "/login"){
        this.$router.push("/login");
      }
    },

    async resetPassword(){

      try{
        this.clearMessageAndErrors();
        this.incrementInProcess();
        
        if(!this.email ){
          this.clearMessageAndErrors();
          this.$store.commit("setError","An email is required.");
          return;
        }

        let resetResponse = await callApi(null, {method: 'POST', url: `/forgot-password`, body: {email: this.email}});
        
        this.clearMessageAndErrors();
          
        if(resetResponse.message) this.$store.commit("setMessage", resetResponse.message);
          
        
      }catch(ex){
        this.clearMessageAndErrors();
        if (ex instanceof ApiNotAvailableError) {
          this.setGlobalError( ex.message);
        } else {
          if (ex instanceof ApiError) throw ex;
          this.setError("Can't reset password.");
        }
      } finally {
        this.password = null;
        this.decrementInProcess();
      }

    },
  }
}