<template lang="pug">
Base(title="Dashboard")
  b-button(:href="user_progress_report_url" target="_blank") Download Program Progress Report

  b-table#progTable(small striped hover :busy="busy" :fields="fields" :items="program_rpt" sort-by="progress" sort-direction="asc" :per-page="perPage" :current-page="currentPage" @row-clicked="toProgram")
  
  b-pagination(small pills v-model="currentPage" :total-rows="program_rpt.length" :per-page="perPage" aria-controls="progTable")
</template>

<script>
import Base from '@/components/Base.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex';
import baseMix from '@/mixins/base'
import {DateTime} from 'luxon'
export default {
  name: 'AdminHome',

  mixins: [baseMix],
  
  components: {
    Base,
  },
  
  computed: {
    ...mapGetters(['jwt','programs']),
    program_rpt(){
      return this.programs.map(p=>{
        p.progress = (p.fields_in_use + p.metrics_in_use + p.geo_metrics_in_use) / p.available_fields;
        return p;
      })
    },
    user_progress_report_url(){
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/org/user-progress-report?token=${this.jwt}`;
    }
  },

  data(){
    return {
      fields: [
        {
          key: "name",
          label: "Program Name"
        },
        {
          key: "progress_date",
          label: "Last Update",
          sortable: true,
          formatter: (value)=>{
            let dt = DateTime.fromSQL(value);
            return dt.toLocaleString(DateTime.DATETIME_MED);
          }
        },
        {
          key: "progress",
          sortable: true,
          class: "text-right",
          formatter: (value, row, item)=>{
            return new Intl.NumberFormat(undefined, { style: 'percent' }).format(value)
          }
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "offer_day_aoa_cord_uod",
          label: "UOD Participation",
          sortable: true,
        },
        {
          key: "aamc_eras_supp_application",
          label: "ERAS Supplemental App",
          sortable: true,
        },
        {
          key: "virtual_interviews",
          label: "Virtual Interviews",
          sortable: true,
        },
        {
          key: "in_person_interviews",
          label: "In-Person Interviews",
          sortable: true,
        },
        {
          key: "status_date",
          label: "Status Last Updated",
          sortable: true,
          formatter: (value)=>{
            let dt = DateTime.fromSQL(value);
            return dt.toLocaleString(DateTime.DATETIME_MED);
          }
        },
        {
          key: "emails",
          label: "Email Contacts",
          class: "col-2",
          sortable: true,
          formatter: (value)=>{
            return value ? value.split(",").join(" "): ""
          }
        },
      ],
      currentPage: 1,
      perPage: 20,
    };
  },

  async created(){
    await this.searchPrograms({search_term: ""});
  },

  methods: {
    ...mapActions(['searchPrograms']),
    toProgram(item){
      this.$router.push(`/programs/${item.id}`);
    },
  },
}
</script>
