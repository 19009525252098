import ssvalidation from '@/mixins/ss-validation'
import which_role from '@/mixins/which-role'

export default {
  
  components:{},
  
  mixins:[
    ssvalidation,
    which_role
  ],

  props:{
    entity: {required: true},
    hint: {type: String},
    is_editing: {type: Boolean, required: false, default: Boolean(false) },
    label: {type: String, required: true},
    label_cols: { type: Number, default: 6 },
    property_name: {type: String, required: true},
    readonly: {type: Boolean, required: false, default: Boolean(false) },
  },


  data(){
    return {
      editing: this.is_editing || false,
      hovered: false,
      localValue: null,
    };
  },

  methods: {
    
    handleHover(hoverState){
      this.hovered = hoverState;
    },

    toggleEdit(){
      if(this.readonly){ this.editing = false; return; }
      this.editing = !this.editing;  
    },

    onUpdate(value){
      this.localValue = value;
      this.$emit('change', this.localValue);
    },
    
  },

};