<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.font9.border.rounded-lg.p-2.d-flex.justify-content-between(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="!editing" @click="toggleEdit" )
    div.text-primary {{ label }}
    div.text-right(v-if="entity[property_name]!==null && entity[property_name]!==''" style="min-width: 120px;")
      span.mr-1(v-if="prepend && unit") {{ unit }}
      span {{ entity[property_name] }}
        small.ml-1(v-if="hint") {{hint}}
      span.ml-1(v-if="!prepend && unit") {{ unit }}
      span.ml-1(v-if="denominator") ({{ (entity[property_name]/denominator).toLocaleString(undefined, {style: 'percent'}) }})
      
    div(v-else )
      span N/A

  b-form-group.m-0.p-1(:label-cols="label_cols" v-if="editing").bg-light
    template(#label)
      div.font8(@click="toggleEdit" style="cursor: pointer;") {{ label }}
    
    b-input-group(size="sm" :prepend="unit" v-if="unit && prepend")
      b-form-input(size="sm" number type="number" :min="min" :max="max" :step="step" v-model="entity[property_name]" :class="validate(property_name)" @update="onUpdate" :disabled="is_na")
    
    b-input-group(size="sm" :append="unit" v-else-if="unit")
      b-form-input(size="sm" number type="number" :min="min" :max="max" :step="step" v-model="entity[property_name]" :class="validate(property_name)" @update="onUpdate" :disabled="is_na")
    
    b-form-input(size="sm" v-else number type="number" :min="min" :max="max" :step="step" v-model="entity[property_name]" :class="validate(property_name)" @update="onUpdate" :disabled="is_na")
              
    small.invalid-feedback {{validationMessage(property_name)}}
    small
      small(v-if="hint") {{hint}}
      b-form-checkbox.mt-1.float-right(size="sm" v-model="is_na" @input="clearValue" v-if="!required" )
      span.small.float-right.mt-2.mr-2(v-if="!required") {{ is_na ? 'not applicable' : 'clear value' }}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import entity_widget from '@/mixins/entity-widget'
export default {
  name: 'EntityNumberField',
  
  props:{
    min: {type: Number, default: 0},
    max: {type: Number, default: 999},
    step: {type: Number, default: 1},
    unit: {type: String},
    prepend: {type: Boolean, default: false},
    denominator: {type: Number}, // Optional. Used to calculate percentage for display.
    required: {type: Boolean, default: false},
  },

  data(){
    return {
      is_na: false,
    }
  },
  
  mixins:[
    entity_widget
  ],

  created(){
    this.is_na = this.entity[this.property_name] == null;
  },

  methods:{
    clearValue(){
      if(this.is_na){
        this.localValue = null;
        this.entity[this.property_name] = null;
      }
    }
  },
}
</script>
<style scoped>
</style>