import { VueOfflineStorage } from 'vue-offline';
import jwtdecode from 'jwt-decode';

export function loadAuthState(store){
  console.debug(`Loading auth state.`)
  let t = VueOfflineStorage.get('jwt');
  if(t){
    parseToken(t, store);
  }
}

/**
 * Parses a JWT, setting offline storage. Mutates the store if provided.
 * @param {string} t jwt token 
 * @param {object} store the store to set
 * @returns parsed JWT info.
 */
export function parseToken(t, store){
  console.debug(`Parsing token.`)
  let parsed = jwtdecode(t);//throws error if invalid

  if(store){
    console.debug(`Setting state into store.`)
    store.state.jwt = t;
    VueOfflineStorage.set('jwt', t);

    store.state.user = parsed.user;
    VueOfflineStorage.set('user', parsed.user);

    store.state.org = parsed.org;
    VueOfflineStorage.set('org', parsed.org);
  }

  return parsed;
} 

          