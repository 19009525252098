<template lang="pug">
b-container#app(fluid).p-0
  b-navbar(toggleable="sm" variant="light")
    .col-3.col-4-md
      b-link(href="https://aoassn.org")
        b-img(src="./assets/aoa_logo.svg" height="90px" style="max-height: 90px")

    .col.text-center
      .bg-light.mb-0.h5(style="color: #212e34") Orthopaedic Residency Information Network (ORIN&reg;)
      span(v-if="banner_ad_active" )
        p.m-0.small Supported by  
        b-link(href="https://bit.ly/3LZ1Tsc" target="_blank")
          b-img(:srcset="banner_ad_image_srcset" :sizes="banner_ad_image_sizes" height="40px" style="max-height: 40px;")

    .col-3.col-4-md(v-if="user" )
      b-navbar-nav.float-right
        b-nav-item(v-if="is_admin" to="/admin") Home
        b-nav-item(v-if="is_admin" to="/orgs") Programs
        b-nav-item(v-if="is_admin || is_bi" to="/qbrowser") Reporting
        b-nav-item(v-if="is_director" :to="'/orgs/'+ org.id") Home
        b-nav-item(to="/search") Search
        b-nav-item-dropdown(right :text="user.username")
          b-nav-item(to="#" @click="logout") Logout
  router-view

</template>

<script>
import authMixin from '@/mixins/auth'
import baseMix from '@/mixins/base'
import whichrole from '@/mixins/which-role'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PublicLayout',
  components: {},

  data() {
    return {
      banner_ad_active: process.env.VUE_APP_BANNER_AD_ACTIVE==='true',
    };
  },
  mixins: [
    authMixin,
    whichrole,
    baseMix
  ],

  computed: {
    ...mapGetters(['global_error','has_global_error']),
    banner_ad_image_srcset(){
      let base = `${process.env.VUE_APP_ASSET_BASE_URL}${process.env.NODE_ENV}/promotions/`
      return `${base}zb_h_970x90.png 728w, ${base}zb_h_300x100.png 300w, `;
    },
    banner_ad_image_sizes(){
      return  `(min-width:800px) 60vw, 300px`;
    }
  },

  methods:{ 
    ...mapActions(['loadCategoryDefs','loadConstants','loadGeography','loadMetricDefs']),
  }
}
</script>
