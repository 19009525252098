<template lang="pug">
b-container#app(fluid).p-0
  .row.bg-light
    .col.ml-1.mt-1
      b-link(href="https://aoassn.org")
        b-img(src="./assets/aoa_logo.svg" height="80px" style="max-height:80px")
    .col.text-right.mr-1.mt-1(style="color: #212e34; font-size: 1.2em; font-weight: bolder;") Orthopaedic Residency Information Network (ORIN&reg;)
  
  .bg-light.text-center(v-if="banner_ad_active")
    small Supported by  
    b-link(href="https://bit.ly/3LZ1Tsc" target="_blank").ml-0
      b-img(:srcset="banner_ad_image_srcset" :sizes="banner_ad_image_sizes" height="35px" style="max-height: 35px;" )

  .m-0.p-0.bg-light
    router-view

</template>

<script>
import baseMix from '@/mixins/base'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PublicMobileLayout',
  components: {},

  data() {
    return {
      banner_ad_active: process.env.VUE_APP_BANNER_AD_ACTIVE==='true',
    };
  },
  mixins: [
    baseMix
  ],

  computed: {
    ...mapGetters(['global_error','has_global_error']),
    banner_ad_image_srcset(){
      let base = `${process.env.VUE_APP_ASSET_BASE_URL}${process.env.NODE_ENV}/promotions/`
      return `${base}zb_h_300x50.png 300w, `;
    },
    banner_ad_image_sizes(){
      return  `300px`;
    }
  },

  methods:{ 
    ...mapActions(['loadCategoryDefs','loadConstants','loadGeography','loadMetricDefs']),

  }
}
</script>

<style>
html, body {
  overflow-x: hidden;
}
body {
  position: relative
}
</style>