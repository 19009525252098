<template lang="pug">
  b-container(fluid)
    b-form(v-if="org")
      b-form-group(label-size="sm" label-cols="6" label="Organization Name")
        b-form-input(size="sm" v-model="org.name" :class="validate('name')")
        small.invalid-feedback {{validationMessage('name')}}
      

</template>

<script>
import {mapGetters} from 'vuex'
import ssvalidation from '@/mixins/ss-validation'
import EntityTextField from '@/components/EntityTextField'

export default {
  name: 'OrgForm',

  components: {
    EntityTextField,
  },

  mixins: [ssvalidation],

  props:{
    org: {type: Object, required: true},
  },

  data(){
    return {};
  },

  computed: {
    ...mapGetters(['message','error','busy','has_error','has_message'])
  },
}
</script>
