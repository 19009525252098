<template lang="pug">
b-container.p-0.mt-1(fluid)
  b-container.font9.border.rounded-lg.p-2(fluid v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="!editing" @click="toggleEdit")
    div.text-primary {{ label }}
    div.text-dark.font9 {{ entity[property_name] }}

  b-container.p-1(fluid v-if="editing").bg-light
    .d-flex.justify-content-between.font8
      div(@click="toggleEdit" style="cursor: pointer;")  {{ label }}
      div {{ chars }} / {{ max_chars }}
    b-form-textarea(size="sm" :rows="rows" :max-rows="max_rows" v-model="entity[property_name]" :class="validate(property_name)" @update="onUpdate")
    small.invalid-feedback {{validationMessage(property_name)}}
    small(v-if="hint") {{hint}}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import entity_widget from '@/mixins/entity-widget'
import ssvalidation from '@/mixins/ss-validation'

export default {
  name: 'EntityTextareaField',
  
  props:{
    rows: {type: Number, default: 3},
    max_rows: {type: Number, default: 5},
    max_chars: {type: Number, default: 500},
    hint: {type: String},
  },
  
  mixins:[
    entity_widget,
    ssvalidation
  ],

  computed: {
    chars(){
      return this.entity && this.entity[this.property_name] ? this.entity[this.property_name].length : 0;
    }
  },
}
</script>
<style scoped>
</style>