<template lang="pug">
b-container.p-0.mt-1(fluid)
  b-container.font9.border.rounded-lg.p-2(fluid v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="!editing" @click="toggleEdit")
    div.text-primary {{ label }}
    div.text-light.font9(v-if="!internal_copy && busy") loading
    div.text-dark.font9(v-if="internal_copy && !busy") {{ internal_copy.content }}

  b-container.p-1(fluid v-if="internal_copy && editing").bg-light
    .d-flex.justify-content-between.font8
      div(@click="toggleEdit" style="cursor: pointer;")  {{ label }}
      div {{ chars }} / {{ max_chars }}
    b-form-textarea(size="sm" :rows="rows" :max-rows="max_rows" v-model="internal_copy.content" :class="validate()" @update="markChanged" )
    small.invalid-feedback {{validationMessage()}}
  .d-flex.justify-content-between(v-if="internal_copy && editing")
    small(v-if="hint") {{hint}}
    b-link(href="#" @click="toggleEdit" )
      b-badge(variant="light" size="sm" )
        b-icon-arrow-bar-up 
        span.ml-1 Done
    b-link(v-show="internal_copy && changed && is_valid" href="#" @click="submitSave")
      b-badge(variant="success" size="sm")
        b-icon-plus
        span.ml-1 Save
    
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import base from '@/mixins/base'
import entity_widget from '@/mixins/entity-widget'
import ssvalidation from '@/mixins/ss-validation'

export default {
  name: 'ProgramTextWidget',
  
  props:{

    org_id: {type: Number, required: true},
    program_id: {type: Number, required: true},
    alias:{type: String, required: true},
    rows: {type: Number, default: 3},
    max_rows: {type: Number, default: 5},
    max_chars: {type: Number, default: 500},
    hint: {type: String},
    
    entity: {required: false},//override mixin
    property_name: {required: false},//override mixin
  },

  data(){
    return {
      internal_copy: null,
      changed: false,
    };
  },
  
  async created(){
    this.internal_copy = await this.getProgramTextForAlias({org_id: this.org_id, program_id: this.program_id, alias: this.alias});
    if(!this.internal_copy){
      this.internal_copy = {
        program_id: this.program_id,
        alias: this.alias,
        content: '',
      };
    }
  },

  mixins:[
    base,
    entity_widget,
    ssvalidation
  ],

  computed: {
    chars(){
      return this.internal_copy && this.internal_copy.content ? this.internal_copy.content.length : 0;
    },
    is_valid(){
      return this.validate() !== "is-invalid";
    },
  },

  methods: {
    ...mapActions(['getProgramTextForAlias','saveProgramText',]),
    markChanged(){
      this.changed = true;
    },
    /** @override */
    submitSave(value){
      //hydrate org_id for action
      this.internal_copy.org_id = this.org_id;
      //save changes.
      this.saveProgramText(this.internal_copy);
      this.changed = false;
    },
    validate(){
      if(this.max_chars && this.internal_copy.content && this.internal_copy.content.length > this.max_chars) return "is-invalid";
      return "";
    },
    validationMessage(){
      if(this.max_chars && this.internal_copy.content && this.internal_copy.content.length > this.max_chars) return `${this.internal_copy.content.length - this.max_chars} characters over limit.`;
      return "";
    }
  }
}
</script>
<style scoped>
</style>