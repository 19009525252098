<template lang="pug">
//- Intended for use as a block of content. Messages, errors, spinner are centered in the block.
div.d-flex.justify-content-center
  b-spinner.mb-1.ml-2(small variant="secondary" v-if="busy")
  span.mb-1.text-danger(v-if="!busy && has_error" ) {{error}}
  span.mb-1.text-info(v-if="!busy && has_message" ) {{message}}
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'StatusWidget',

  props: {},

  computed: {
    ...mapGetters(['busy','error','has_error','has_message','message',]),
  },

  data(){
    return {};
  },

}
</script>
