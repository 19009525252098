<template lang="pug">
b-container.p-0.mt-1(fluid)
  b-container.font9.border.rounded-lg.p-2(fluid  v-if="metric")
    div.d-flex.justify-content-between
      div.text-primary(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" @click="toggleEdit") {{ label }}
      div.text-dark.font8(v-if="!values || values.length===0")
        span(v-if="empty_value") {{empty_value}}
        span.text-secondary(v-else) - - - 

    .text-dark.font8(v-if="metric && values && !editing" @click="toggleEdit")
      div(v-if="metric.display_type==='paragraph'")
        ul
          li(v-for="v in values" :key="v.id") {{ v.value }}
          
      div(v-else)
        
        div.d-flex.justify-content-between(v-for="v in values" :key="v.id")
          span {{ v.category_option_label || v.metric_option_label || "" }}
          span {{ v.value }}

  //- Metrics that gather entries but don't have options
  .text-dark.ilbl(v-if="metric && editing && (!metric_options || metric_options.length===0) && (!category_options || category_options.length===0)")
    ProgramMetricInput(v-for="v in values" :key="v.id" 
      :metric="metric" 
      :program_metric="v"
      @pm-saved="reloadValues"
      @pm-removed="reloadValues")
    
    ProgramMetricInput( v-if="new_program_metric" 
      :metric="metric" 
      :program_metric="new_program_metric"
      @pm-saved="reloadValues"
      @pm-removed="reloadValues")

  //- Metrics that gather data over categories
  .text-dark.ilbl(v-if="metric && editing && category_options")
    ProgramMetricCategoryInput(v-for="v in values" :key="v.id" 
      :category_options="category_options" 
      :metric="metric" 
      :program_metric="v" 
      @pm-saved="reloadValues"
      @pm-removed="reloadValues")
    
    ProgramMetricCategoryInput( v-if="new_program_metric" 
      :category_options="available_category_options" 
      :metric="metric" 
      :program_metric="new_program_metric"
      @pm-saved="reloadValues"
      @pm-removed="reloadValues")
    
  .d-flex.justify-content-between(v-if="metric && editing")
    b-link(href="#" @click="toggleEdit" )
      b-badge(variant="light" size="sm" )
        b-icon-arrow-bar-up 
        span.ml-1 Done
    b-link(href="#" @click="initProgramMetric")
      b-badge(variant="success" size="sm")
        b-icon-plus
        span.ml-1 Add
          

</template>

<script>
import {mapGetters} from 'vuex'
import ssvalidation from '@/mixins/ss-validation'
import which_role from '@/mixins/which-role'
import ProgramMetricInput from '@/components/ProgramMetricInput'
import ProgramMetricCategoryInput from '@/components/ProgramMetricCategoryInput'

export default {
  name: 'ProgramMetricWidget',
  
  components:{
    ProgramMetricInput,
    ProgramMetricCategoryInput
  },
  
  mixins:[
    ssvalidation,
    which_role
  ],

  props:{
    category_options: {type: Array},
    empty_value: {type: String},
    is_editing: {type: Boolean, default: Boolean(false) },
    label: {type: String, required: true},
    metric: {type: Object},//hide when missing.
    metric_options:  {type: Array},
    program_id: {required: true},
    readonly: {type: Boolean, default: false},
    values: {type: Array},
  },


  data(){
    return {
      hovered: false,
      editing: this.is_editing || false,
      new_program_metric: null,
      confirming: false,
    };
  },

  computed: {
    ...mapGetters(['busy','org','program','program_metric']),
    
    metric_input_type(){
      if(!this.metric || !this.metric.data_type) return "text";
      if(this.metric.data_type==="integer") return "number";
      return "text";
    },

    //unused among values
    available_category_options(){
      if(!this.category_options) return [];
      if(!this.values) return this.category_options;
      let used_options = this.values.map(v=>v.category_option_id);
      return this.category_options.filter(co=>!used_options.includes(co.id));
    },
  },

  watch:{},

  methods: {
    toggleEdit(){
      if(this.readonly){ this.editing = false; return; }
      console.debug("changing state");
      if(!this.is_admin && !this.is_director){ this.editing = false; return; }
      
      this.editing = !this.editing;
    },

    handleHover(hoverState){
      this.hovered = hoverState;
    },
    
    async initProgramMetric(){
      this.new_program_metric = {
        program_id: this.program.id,
        metric_id: this.metric.id,
        value: "",
      };
    },

    reloadValues(){
      //If it was new, clear the current vuex object
      
      this.$emit("pm-reload");
      setTimeout(()=>{
        this.new_program_metric = null;
      }, 500);
      
    }
  },


}
</script>
<style scoped>
.ilbl{
  font-size: .7em;
}

</style>