<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.font9.border.rounded-lg.p-2
    div.d-flex.justify-content-between(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="!editing" @click="toggleEdit" )
      div.text-primary {{ label }}
      div(v-if="tf_labels && tf_labels.length===2") {{ entity[property_name] ? tf_labels[0] : tf_labels[1] }}
    slot(name="more_info")

  b-form-group.m-0.p-1(:label-cols="label_cols" v-if="editing").bg-light
    template(#label)
      div.font8(@click="toggleEdit" style="cursor: pointer;") {{ label }}
        .small(v-if="hint") {{hint}}
    b-form-checkbox.mt-1.float-right(size="sm" v-model="entity[property_name]" :class="validate(property_name)" switch @update="onUpdate")
      span.small(v-if="tf_labels && tf_labels.length===2") {{ entity[property_name] ? tf_labels[0] : tf_labels[1] }}
    small.invalid-feedback {{validationMessage(property_name)}}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import entity_widget from '@/mixins/entity-widget'
export default {
  name: 'EntityBooleanField',

  mixins:[
    entity_widget
  ],

  props:{
    tf_labels: {type: Array},
    hint: {type: String},//only shown on input
  },

}
</script>
<style scoped>
</style>