<template lang="pug">
  b-container(fluid).p-0
    Base(title="Login" v-if="!forgot")
      b-form
        input(type="hidden" name="redirect_uri" value=redirect_uri||"")
        b-form-group(label="Username" )
          b-form-input(name="username" v-model="username")
        b-form-group(label="Password")
          b-form-input(type="password" v-model="password")
        b-row
          b-col
            b-button(pill variant="primary" @click="login") Login
        b-row
          b-col.pt-3
            b-link.text-secondary(@click="toggleForgot") Forgot password?
    
    Base(title="Reset your Password" v-if="forgot")
      b-card-body
        b-form
          b-form-group(label="Email" )
            b-form-input(name="email" v-model="email")
          b-row
            b-col
              b-button(pill variant="primary" @click="resetPassword") Reset My Password
          b-row
            b-col.pt-3
              b-link.text-secondary(@click="toggleForgot") Login
</template>

<script>
import Base from '@/components/Base'
import {mapGetters} from 'vuex'
import auth from '@/mixins/auth'
export default {
  name: 'Login',
  components:{
    Base,
  },

  mixins: [auth],

  computed: {
    ...mapGetters(['message','error','errors','busy','has_error','has_message']),
  },

  data(){
    return {
      forgot: false
    };
  },

  methods: {
    toggleForgot(){
      this.forgot = !this.forgot;
    }
  }

}
</script>
