<template lang="pug">
b-container.p-0.mt-1(fluid)
  b-container.font9.border.rounded-lg.p-2(fluid v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="geo_metric && !editing" @click="editValues")
    div(v-if="geo_metric.display_type==='percent'")
      div.text-primary {{ label }}
      b-table(small striped :fields="pct_table_fields" :items="percentage_views")

    div(v-else-if="geo_metric.display_type==='sum'")
      div.text-primary {{ label }}
      b-table(small striped  :fields="sum_table_fields" :items="sum_views")

    div(v-else)
      div.d-flex.justify-content-between(v-for="v in values" :key="v.id")
        span {{ v.category_option_label || v.metric_option_label || "" }}
        span {{ v.value }}

  //- Gather data over geographies (states and countries)
  b-modal(v-if="geo_metric" :id="modalId" @ok="submitCurrentValues" ok-title="Save Changes" @cancel="cancelEdit" :title="modalTitle")
    small Please enter counts of residents for each country/state combination. To change existing counts, you can click on the row and adjust the total.
    b-button(@click="addNewCurrentEntry")
      b-icon-plus
      span.ml-2 New Entry
    
    b-list-group
      b-list-group-item(v-for="geo,idx of current_values" :key="idx" href="#" :active="entryIsSelected(geo)")
        .row(v-if="!entryIsSelected(geo)")
          .col-10.d-flex.justify-content-between(@click="selectCurrentEntry(geo)")
            span {{country_name(geo)}}
            span {{state_name(geo)}}
            span {{geo.value}}
          .col-2.text-right
            b-icon-x-circle(variant="danger" @click="removeCurrentValue(geo)" )
        b-form(inline v-else)
          b-form-row
            .col-6
              b-form-select.col(:options="country_options" v-model="current_entry.country")
            .col-4(v-if="state_options && state_options.length > 0")
              b-form-select.col(:options="state_options" v-model="current_entry.state" :disabled="geo.id>0")
            .col-2
              b-form-input.col(type="number" v-model="current_entry.value" min="0")
            
    //- .small.text-dark Add your geographic count data quickly by providing data as: 
    //-   span.text-info(style="") country, state, value  ({{ geo_metric.data_type }}) 
    //-   .span.text-dark Separate each field by commas. Enter each set of data on a new line Example:
    //- small
    //-   code USA,IL,5
    //- .small
    //-   code CAN,QB,3
    //- .small
    //-   code MEX,,2
    //- .small(v-if="geo_metric.display_type==='percent'") Note: your data 
    //-   span.text-info counts 
    //-   span - not as 
    //-   span.text-info percentages
    //-   span ! The application will automatically calculate and display the percentages.
    
    //- b-form-textarea(max-rows="rows" rows="10" columns="40" v-model="quick_input")

    //- .small.text-dark Any new entries will be added and existing ones will be updated or removed as necessary. Any unrecognized 3-letter country codes or two-letter state abbreviations will be ignored. 
    
    StatusWidget
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ssvalidation from '@/mixins/ss-validation'
import { usa_states, can_provinces, countries } from '@/mixins/geo'
import ProgramMetricInput from '@/components/ProgramMetricInput'
import StatusWidget from '@/components/StatusWidget'

export default {
  name: 'ProgramGeographicMetricWidget',
  
  components:{
    ProgramMetricInput,
    StatusWidget
  },
  
  mixins:[
    ssvalidation
  ],

  props:{
    catch_all_label: {type: String},
    geo_metric: {type: Object},
    is_editing: {type: Boolean, required: false, default: Boolean(false) },
    label: {type: String, required: true},
    program: {required: true},
    readonly: {type: Boolean, default: false},
    short_label: {type: String, required: true},
    values: {type: Array},
    
  },


  data(){
    return {
      hovered: false,
      editing: this.is_editing || false,
      new_program_geo_metric: null,
      confirming: false,
      current_entry: null,
      current_values: [],
      quick_input: "",
      quick_input_delim: ",",
      pct_table_fields:[
        {
          key: "label", label: this.short_label, sortable: true,
          formatter: (v)=>{return v || this.catch_all_label || "Other"; },
        },
        {
          key: "value", label: "%", sortable: true, class: "text-right",
          formatter: (v)=>{return Number(v).toLocaleString(undefined, {style: 'percent'}); },
        }
      ],
      sum_table_fields:[
        {
          key: "label", label: this.short_label, sortable: true,
          formatter: (v)=>{return v || this.catch_all_label || "Other"; },
        },
        {
          key: "value", label: "%", sortable: true, class: "text-right",
        }
      ],
    };
  },

  computed: {
    ...mapGetters(['has_error']),
    modalId(){
      return 'geo_metric_vals'+this.geo_metric.id;
    },

    modalTitle(){
      return "Editing values for: "+this.geo_metric.group_label;
    },

    sum_views(){
      if(!this.values) return [];
      let sums = [];
      //build the aggregate sum map.
      this.values.forEach(pgm=>{
        let existing = sums.find(x=>x.label == pgm.geo_group_name);
        if(!existing){
          existing = {label: pgm.geo_group_name, value: 0};
          sums.push( existing );
        }
        existing.value += Number(pgm.value);
      });
      return sums;
    },
    percentage_views(){
      if(!this.values) return [];
      let sum = this.values.reduce((total, pgm)=>{
        total += Number(pgm.value);
        return total;
      }, 0);

      if(sum === 0) return [];

      //calculate percentages
      return this.sum_views.map(s=>{
        let pct= {};
        Object.assign(pct, s);
        pct.value = (pct.value / sum);
        return pct;
      });
      
    },

    metric_input_type(){
      if(!this.geo_metric || !this.geo_metric.data_type) return "text";
      if(this.geo_metric.data_type==="integer") return "number";
      return "text";
    },

    country_options(){
      return countries;
    },
    state_options(){
      if(this.current_entry && this.current_entry.country){
        if(this.current_entry.country==='USA'){
          return usa_states;
        } else if (this.current_entry.country==='CAN'){
          return can_provinces;
        }
      }
    }
    

  },

  watch:{},

  methods: {
    ...mapActions(['removeProgramGeoMetrics','replaceProgramGeoMetrics']),
    state_name(geo){
      let country = countries.find(c=>c.value==geo.country);
      if(country){
        if(geo.country==='USA'){
          let state = usa_states.find(s=>s.value===geo.state);

          return state ? state.text  : geo.state;
        }
        if(geo.country==='CAN'){
          let state = can_provinces.find(s=>s.value===geo.state) || geo.state;
          return state ? state.text  : geo.state;
        }
      }
      return geo.state;
    },
    country_name(geo){
      let country = countries.find(c=>c.value==geo.country);
      return country ? country.text : geo.country;
    },

    editValues(){
      if(this.readonly) return;
      // this.initQuickInput();
      this.initCurrentValues();
      this.$bvModal.show(this.modalId);
    },
    cancelEdit(){
      this.current_entry = null;
      this.current_values = [];
      this.$bvModal.hide(this.modalId);
    },
    handleHover(hoverState){
      this.hovered = hoverState;
    },
    
    /** @deprecated */
    initQuickInput(){
      this.quick_input = this.values.reduce((agg, pgm)=>{
        agg += `${pgm.country||''}${this.quick_input_delim}${pgm.state||''}${this.quick_input_delim}${pgm.value}\n`;
        return agg;
      },'');
    },

    initCurrentValues(){
      this.current_values = this.values.map(v=>{return v});
      this.current_values.sort((a,b)=>{
        var textA = `${a.country}|${a.state}`;
        var textB = `${b.country}|${b.state}`;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    
    /** @deprecated */
    async submitValues(ev){
      ev.preventDefault();
      let payload = {
          org_id: this.program.org_id,
          program_id: this.program.id,
          geo_metric_id: this.geo_metric.id,
          values: [],
        };
      let lines = this.quick_input.trim().split('\n');
      if(lines.length > 0){
 
        payload.values = lines.map(line=>{
          let data = line.split(this.quick_input_delim);
          
          if(!data[0] && !data[1] && data[2]) return null;

          return {
            program_id: this.program.id,
            geo_metric_id: this.geo_metric.id,
            country: data[0],
            state: data[1],
            value: data[2]
          }

        });
        await this.replaceProgramGeoMetrics(payload)
      } else {
        await this.removeProgramGeoMetrics(payload)
      }
      if(!this.has_error){
        this.$emit("pm-reload");
        setTimeout(()=>{
          this.cancelEdit();
          this.quick_input = "";
        }, 500);
      }

    },

    entryIsSelected(geo){
      if(!this.current_entry) return false;
      if(geo.id){
        return this.current_entry.id===geo.id;
      } else {
        return this.current_entry.country === geo.country && this.current_entry.state === geo.state;
      }
    },
    removeCurrentValue(geo){
      if(!geo) return;
      let valueIndex = this.current_values.findIndex(v=>v.country===geo.country && v.state===geo.state);
      if(valueIndex>=0){
        this.current_values.splice(valueIndex,1);
      }
    },

    addNewCurrentEntry(){
      this.current_entry = {
        state: "",
        country: "",
        value: null
      };
      this.current_values.splice(0,0,this.current_entry);
      return;
    },

    selectCurrentEntry(geo){
      this.current_entry = this.current_values.find(v=>v.country===geo.country && v.state===geo.state);
    },

    async submitCurrentValues(ev){
      ev.preventDefault();
      
      let payload = {
          org_id: this.program.org_id,
          program_id: this.program.id,
          geo_metric_id: this.geo_metric.id,
          values: [],
        };

      let valid_with_counts = this.current_values.filter(cv=>{
        if(!cv.value || cv.value < 0) return false;
        if(['USA'].includes(cv.country) && !cv.state) return false;
        return true;
      });
      
      payload.values = valid_with_counts.map(v=>{
        v.program_id = this.program.id;
        v.geo_metric_id = this.geo_metric.id;
        return v;
      });
      if(this.current_values.length === 0){
        await this.removeProgramGeoMetrics(payload)
      } else {
        await this.replaceProgramGeoMetrics(payload)
      }
      
      if(!this.has_error){
        this.$emit("pm-reload");
        setTimeout(()=>{
          this.cancelEdit();
          this.quick_input = "";
        }, 500);
      }
    },

    reloadValues(){
      //If it was new, clear the current vuex object
      
      this.$emit("pm-reload");
      setTimeout(()=>{
        this.quick_input = "";
      }, 500);
      
    }
  },


}
</script>
<style scoped>
.ilbl{
  font-size: .7em;
}
textarea {
  font-size: 1em;
}

</style>