import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueOffline from 'vue-offline';
import VueRouter from 'vue-router'
import './plugins/bootstrap-vue'
import App from './App.vue'
import routes from './router'
import store from './store'
import {loadAuthState} from './lib/auth-state'
Vue.use(VueOffline);
Vue.use(VueRouter);

Vue.config.productionTip = false

const router = new VueRouter({
  routes
})

const OPEN_ROUTES = ['About','Login','Password','Unauthorized','Search','MobileSearch','ViewProgramDetails','ViewProgramDetailsMobile'];
const ADMIN_ROUTES = ['AdminHome','EditProgram','ManageOrgs','ManageMetrics','QueryBrowser','ViewOrg'];
const BI_ROUTES = ['BIHome','QueryBrowser'];
const DIRECTOR_ROUTES = ['DirectorHome','EditProgram','ViewOrg'];

router.beforeEach((to, from, next) => {
  loadAuthState(store); // for every route, the auth state is re-inspected and validated
  let user = store.state.user;
  let roles = user ? user.roles || [] : [];
  console.debug(`roles: ${roles.join(',')} --> requesting: ${to.name}`)
  if(to.path===from.path){ 
    console.debug(`  The paths already match.`)
    next(false); 
  } else  if(OPEN_ROUTES.includes(to.name) ){
    console.debug(`  It's an open route.`)
    next();
  } else if (!user || !roles){
    console.debug(`  Unauthorized. No user or role is available.`)
    // next();
    next({name: "Unauthorized"});
  } else {
    if( ( roles.includes('admin') && ADMIN_ROUTES.includes(to.name)
      || ( roles.includes('director') && DIRECTOR_ROUTES.includes(to.name) )
      || ( roles.includes('industry') && INDUSTRY_ROUTES.includes(to.name) )
      || ( roles.includes('bi') && BI_ROUTES.includes(to.name) ) ) ){
      // role and requested route name match
      console.debug(`  The route is authorized for this role.`)
      next();
    } else {
      console.debug(`  The route is not authorized for this role.`)
      // unauthorized
      next({name: "Unauthorized"});
    }
  }
});

new Vue({
  router,
  store,
  data: {
    open_routes: OPEN_ROUTES,
  },
  render: h => h(App)
}).$mount('#app')
