<template lang="pug">
div.p-3.border-bottom( v-b-hover="whenHovered" :class="hovered ? 'hov bg-secondary' : ''" @click="viewDetails")
  .row
    .col-8
      b-img.mr-2(v-if="logo_url" height="50px" :src="logo_url")
      span.h5.text-primary {{program.name}}
      span(v-if="is_admin")
        b-badge.ml-2(style="font-size: .7em" pill :variant="program.status==='published'?'success':'secondary'") {{program.status}}
      p.m-0.mb-1.text-dark(v-show="program.city && program.state") {{program.city}}, {{program.state}} 
        span(v-show="program.program_type") | {{ program.program_type }}
    .col.text-right.text-primary(style="font-size: .8em;")
      //- b-icon-star
      //- span.ml-2 Add to Favorites
      span.ml-2(v-show="last_updated") {{ last_updated }}
  .row(style="font-size: .8em;")
    .col Num Residents
    .col Length of Training
    //- .col Dedicated Research
    .col Trauma Level
    .col Num Fellowships
    //- .col Median USMLE Step 1
    .col Median USMLE Step 2 

  .row.text-info.mb-1
    .col {{ integer( program.residents_total ) }}
    .col {{ program.length_of_training || "N/A" }}
    //- .col {{ program.weeks_dedicated_research_time>0?'yes':'no'}}
    .col {{ integer( program.trauma_level ) }}
    .col {{ integer( program.fellowship_count ) }}
    //- .col {{ integer( program.step1_median ) }}
    .col {{ integer( program.step2_median ) }}

  .row(style="font-size: .8em;")
    .col % Female Residents
    .col % URiM Residents
    .col % Residents Completed an Away
    .col # Rotators Offered an Interview
    .col Median COMLEX-USA Level 1
    .col Median COMLEX-USA Level 2

  .row.text-info
    //- .col 
    //-   .small(v-if="program.graduates_considered_d_o") {{program.graduates_considered_d_o.length < 10 ? program.graduates_considered_d_o : "some D.O."}}
    //-   .small(v-if="program.graduates_considered_intl") {{program.graduates_considered_intl.length < 10 ? program.graduates_considered_intl : "some International"}}
    .col {{ percentage( program.residents_female, program.residents_total ) }}
    .col {{ percentage( program.residents_minority, program.residents_total ) }}
    .col {{ percentage( program.residents_elective_rotation, program.residents_total ) }}
    .col {{ integer( program.yearly_rotator_interviews ) }}
    .col {{ integer( program.comlex_usa_level1_median ) }}
    .col {{ integer( program.comlex_usa_level2_median ) }}

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import which_role from '@/mixins/which-role'
import {DateTime} from 'luxon'
export default {
  name: 'ProgramSearchResult',
  
  mixins: [which_role],

  props:{
    program: {type: Object, required: true},
  },


  data(){
    return {
      hovered: false,
      asset_base_url: process.env.VUE_APP_ASSET_BASE_URL,
    };
  },

  computed: {
    ...mapGetters(['busy']),
    logo_url(){
      if(!this.program.logo) return "";
      return process.env.VUE_APP_ASSET_BASE_URL + this.program.logo;
    },
    last_updated(){
      if(this.program && this.program.updated){ 
        let upd = DateTime.fromSQL(this.program.updated, {zone: 'UTC'});
        let rezoned = upd.setZone();//use local
        return `Last Updated ${rezoned.toLocaleString(DateTime.DATETIME_MED)}`;
        // return `Last Updated ${DateTime.fromSQL(this.program.updated).toLocaleString(DateTime.DATETIME_MED)}` ;
      }
      return '';
    }
  },

  methods: {
    ...mapMutations(['setProgram']),
    whenHovered(hoverState){
      this.hovered = hoverState;
    },

    viewDetails(){
      this.setProgram(this.program);
      this.$router.push(`/program-details/${this.program.id}`);
    },

    percentage(num, denom){
      if(denom == 0 || denom == null || denom==='' || !isFinite(denom)) return 'N/A';
      if(num == null || num==='' || !isFinite(num)) return 'N/A';
      return Number(num/denom).toLocaleString(undefined, {style: 'percent'})
    },

    integer(num){
      if(num == null || num==='' || !isFinite(num)) return 'N/A';
      return num;
    }
    
  },


}
</script>
<style scoped>
.hov{
  cursor: pointer;
}

</style>