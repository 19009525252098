<template lang="pug">
.d-flex.justify-content-between.border-bottom.pt-2.pb-2( v-b-hover="whenHovered" :class="hovered ? 'hov bg-secondary' : ''" @click="viewDetails")
  .text-primary(style="font-size: 1em;") {{program.name}}
  .p-0(style="width:60px; overflow: hidden;" v-if="logo_url" )
    b-img.mr-2(height="50px" :src="logo_url" fluid)
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import which_role from '@/mixins/which-role'
import {DateTime} from 'luxon'
export default {
  name: 'ProgramSearchResultMobile',
  
  mixins: [which_role],

  props:{
    program: {type: Object, required: true},
  },


  data(){
    return {
      hovered: false,
      asset_base_url: process.env.VUE_APP_ASSET_BASE_URL,
    };
  },

  computed: {
    ...mapGetters(['busy']),
    logo_url(){
      if(!this.program.logo) return "";
      return process.env.VUE_APP_ASSET_BASE_URL + this.program.logo;
    },
    last_updated(){
      if(this.program && this.program.updated){ 
        let upd = DateTime.fromSQL(this.program.updated, {zone: 'UTC'});
        let rezoned = upd.setZone();//use local
        return `Last Updated ${rezoned.toLocaleString(DateTime.DATETIME_MED)}`;
        // return `Last Updated ${DateTime.fromSQL(this.program.updated).toLocaleString(DateTime.DATETIME_MED)}` ;
      }
      return '';
    }
  },

  methods: {
    ...mapMutations(['setProgram']),
    whenHovered(hoverState){
      this.hovered = hoverState;
    },

    viewDetails(){
      this.setProgram(this.program);
      this.$router.push(`/program-details/${this.program.id}`);
    },

  },


}
</script>
<style scoped>
.hov{
  cursor: pointer;
}

</style>