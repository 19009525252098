<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.font9.border.rounded-lg.p-2.d-flex.justify-content-between(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" v-if="!editing" @click="toggleEdit" )
    div.text-primary {{ label }}
    div {{ selected.text || "" }}

  b-form-group.m-0.p-1(:label-cols="label_cols" v-if="editing").bg-light
    template(#label)
      div.font8(@click="toggleEdit" style="cursor: pointer;") {{ label }}
    b-form-select(size="sm" v-model="entity[property_name]" :class="validate(property_name)" :options="options" @update="onUpdate")
    small.invalid-feedback {{validationMessage(property_name)}}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import entity_widget from '@/mixins/entity-widget'

export default {
  name: 'EntityDropdownField',
  
  computed:{
    selected(){
      let selected = this.options.find(opt=>opt.value==this.entity[this.property_name]);
      return selected || {text:"", value:null};
    }
  },

  props:{
    options: {type: Array, required: true}
  },
  
  mixins:[
    entity_widget
  ],

}
</script>
<style scoped>
</style>