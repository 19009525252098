<template lang="pug">
b-container#app(fluid ).p-0.bg-light
  b-overlay(:show="has_global_error")
    template( #overlay )
      div.text-center.text-danger
        b-icon-exclamation-triangle
        p {{global_error}}
        b-button(v-if="auth_error_count > 0" variant="outline-danger" @click="logout") Login
    router-view

    //- .text-center(style="max-width: 790; max-height: 90px" )
      b-img(v-if="banner_ad_image" :src="banner_ad_image"  fluid)
 
  
  .mt-0.bg-light.text-center.small
    span © 2021 American Orthopaedic Association. All rights reserved. May not be used, copied, distributed, or published without express written permission of the AOA. Residency programs are solely responsible for providing and updating information and assuring the accuracy of all reported data. | 
    span
      b-link(href="https://www.aoassn.org/privacy-policy/" target="_blank") Privacy Policy
</template>

<script>
// import aoa_logo from '@/assets/aoa_logo.svg'
import baseMix from '@/mixins/base'
import authMix from '@/mixins/auth'
import { mapGetters, mapActions } from 'vuex'
export default {
  
  data() {
    return {};
  },
  mixins: [
    baseMix,
    authMix
  ],

  computed: {
    ...mapGetters(['auth_error_count','global_error','has_global_error',]),
  },

  created(){
    //Always load globals...
    this.loadCategoryDefs();
    this.loadConstants();
    this.loadGeography();
    this.loadMetricDefs();
    if(!this.$router.currentRoute.name){
      //Empty routes default to search
      this.$router.push('/search');
    } else if(this.$root.open_routes.includes(this.$router.currentRoute.name)){
      //Open routes don't require login. Don't redirect. 
    }
  },

  methods:{ 
    ...mapActions(['loadCategoryDefs','loadConstants','loadGeography','loadMetricDefs']),
  }
}
</script>
