<template lang="pug">
  Base
    template(#title)
      b-card-text.d-flex.justify-content-between
        div
          b-button(pill size="sm" variant="light" @click="$router.go(-1)" v-if="is_admin")
            b-icon-arrow-left-short
          span.ml-2
            span {{'\uD83C\uDF93'}}
            span.ml-2 {{ org ? org.name : 'Organization'}}
          b-link.ml-2(variant="light" @click="toggleEditing")
            b-icon-pencil
        div
          StatusWidget
          
      b-alert(variant="warning" show v-if="!org && !busy") No organization found. Please go back.
      
    b-overlay(:show="busy")
      b-container(fluid v-if="org")
        b-button(v-if="programs && programs.length>0 && !editing" variant="outline-primary" @click="selectProgram" ) Edit Program Info
        b-button(v-if="programs && programs.length>0 && is_admin && !confirmingOrgDelete" variant="outline-danger" @click="confirmDeleteOrg" ) Delete this Organization

        b-container(v-show="is_admin && confirmingOrgDelete").text-center.p-3.bg-light.text-danger
          p Please confirm you want to delete this organization and all its data. You cannot undo this action!  
          b-button(variant="outline-danger" @click="submitDeleteOrg") Yes, delete this organization.
      
        //- .mt-3.text-dark Residency Programs
        //- b-table(small striped hover show-empty tbody-tr-class="selectablerow" :fields="program_fields" :items="programs" @row-clicked="selectProgram" empty-text="No programs yet." )
        
        //- b-button-group(v-if="is_admin || (!is_admin && programs.length === 0)")
        //-   b-button(variant="outline-primary" @click="createProgramModal" ) Create Program

        .mt-3.text-dark Current Users
        b-table(small striped hover show-empty tbody-tr-class="selectablerow" :fields="user_fields" :items="users" @row-clicked="selectUser" empty-text="No users yet.")
        
        b-button-group(v-if="is_admin")
          b-button(variant="outline-primary" @click="initUser" ) Create User
        
        b-modal#m-org(title="Edit Organization" ok-title="Save" @ok="submitSaveOrg")
          p Your organization name can have a different name than your program name.
          b-form
            b-form-row
              b-form-group.col-6(label-size="sm" label-cols="2" label="Name" )
                b-form-input(size="sm" v-model="org.name" :disabled="busy||!editing")
              
              .col.text-right.text-secondary(style="font-size: .8em")
                div(v-if="org.created") Created {{formatDateTime(org.created)}}          
                div(v-if="org.updated") Updated {{formatDateTime(org.updated)}}

              
        b-modal#m-user
          template(#modal-title)
            span {{ user_account && user_account.user_id ? 'Edit' : 'Create' }} User
          template(#modal-footer)
            b-container(fluid).d-flex.justify-content-between
              b-button(variant="outline-danger" v-if="user_account && user_account.user_id && !userDeleteConfirmed" @click="confirmDeleteUser") Delete
              b-button(variant="danger" v-if="user_account && user_account.user_id && userDeleteConfirmed" @click="submitDeleteUser") Click Again to Delete
              b-button(variant="secondary" @click="cancelUserEdit") Cancel
              b-button(variant="info" v-if="user_account" @click="submitSaveUser") Save
          StatusWidget.mb-1
          b-form(v-if="user_account")
            b-form-group(label-cols="4" label="Username")
              b-form-input(v-model="user_account.username" :class="validate('username')" )
              small.invalid-feedback {{validationMessage('username')}}
            
            b-form-group(label-cols="4" label="First Name")
              b-form-input(v-model="user_account.first_name" :class="validate('first_name')" )
              small.invalid-feedback {{validationMessage('first_name')}}
            
            b-form-group(label-cols="4" label="Last Name")
              b-form-input(v-model="user_account.last_name" :class="validate('last_name')" )
              small.invalid-feedback {{validationMessage('last_name')}}
            
            b-form-group(label-cols="4" label="Email")
              b-form-input(v-model="user_account.email" :class="validate('email')" )
              small.invalid-feedback {{validationMessage('email')}}
          
            b-form-group(label-cols="4" label="Mobile Phone")
              b-form-input(v-model="user_account.mobile_phone" :class="validate('mobile_phone')" )
              small.invalid-feedback {{validationMessage('mobile_phone')}}

            b-form-group(label-cols="4" label="Timezone")
              b-form-select(v-model="user_account.timezone" :class="validate('timezone')" :options="timezone_opts" )
              small.invalid-feedback {{validationMessage('timezone')}}

            b-form-group(label-cols="4" label="Manually Assign Password?" v-if="is_admin")
              b-form-checkbox(v-model="manuallyAssignPassword" )
  
            b-form-group(label-cols="4" label="Password" v-if="is_admin && manuallyAssignPassword")
              b-form-input(type="password" v-model="user_account.password" :class="validate('password')" )
              small.invalid-feedback {{validationMessage('password')}}
            
            b-form-group(label-cols="4" label="Status" v-if="is_admin")
              b-form-select(v-model="user_account.status" :class="validate('status')"  )
                b-form-select-option(value="active") Active
                b-form-select-option(value="pending") Pending
                b-form-select-option(value="suspended") Suspended
                
              small.invalid-feedback {{validationMessage('status')}}
          
            //- b-form-group(label-cols="4" label="Must Reset Password" v-if="is_admin")
            //-   b-form-checkbox(v-model="user_account.must_reset_password" :class="validate('must_reset_password')" )
            //-   small.invalid-feedback {{validationMessage('must_reset_password')}}

            .text-right.text-primary(style="font-size: .8em;" v-if="user_account.id")
              b-badge(variant="warning" v-if="!user_account.email_verified") Email not yet verified.
              div Last Login {{formatDateTime(user_account.last_login)}}
              div Updated {{formatDateTime(user_account.updated)}}
              div Created {{formatDateTime(user_account.created)}}
        
        b-modal#progPanel( @ok="submitSaveOrg" ok-title="Save" title="Program")
          StatusWidget
          b-form(v-if="org")
            b-form-group(label-size="sm" label-cols="3" label="Name" )
              b-form-input(size="sm" v-model="org.name" :disabled="busy")
</template>

<script>
import Base from '@/components/Base.vue'
import StatusWidget from '@/components/StatusWidget.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import baseMix from '@/mixins/base'
import ssvalidation from '@/mixins/ss-validation'
import which_role from '@/mixins/which-role'
import {listTimeZones} from 'timezone-support';

export default {
  name: 'ViewOrg',

  mixins: [baseMix, ssvalidation, which_role],
  
  components: {
    Base,
    StatusWidget
  },
  
  computed: {
    ...mapGetters(['org','program','programs','users','user_account']),
    timezone_opts(){
      return listTimeZones().filter(tz=>tz.startsWith('America')).map(tz=>{return {text: tz, value: tz};});
    },
    userStatus(){
      if(this.user_account) return this.user_account.status;
      return "";
    }
  },

  data(){
    return {
      editing: false,
      org_id: null,
      manuallyAssignPassword: false,
      userDeleteConfirmed: false,
      confirmingOrgDelete: false,
      user_fields:[
        {
          key:'username'
        },
        {
          key:'email'
        },
        {
          key:'first_name'
        },
        {
          key:'last_name'
        },
        {
          key:'status'
        },
      ],
      program_fields:[
        {
          key:'name'
        },
        {
          key:'program_type'
        },
        {
          key:'chairman',
          label: 'Chairperson'
        },
        {
          key:'contact_name'
        },
        {
          key:'contact_email'
        },
        {
          key:'opts',
          label: '',
        },
      ],
    };
     
  },

  watch: {
    $route() {
      this.checkRouteChanged();
    },
    org_id(){
      this.checkRouteChanged();
    },
    userStatus(newStatus, oldStatus){
      //When status goes from 'suspended' to 'active', adjust some other user properties.
      if(newStatus==='active' && oldStatus==='suspended'){
        this.user_account.bad_login_attempts = 0;
      }
    }
  },

  created(){
    this.checkRouteChanged();
  },

  methods: {
    ...mapActions(['deleteUserAccount','deleteOrg','loadOrg','saveUserAccount','saveOrg','saveProgram','searchOrgs','searchPrograms','searchUsers',]),
    ...mapMutations(['setProgram','setUserAccount','clearMessageAndErrors']),
    cancelUserEdit(){
      this.userDeleteConfirmed = false;
      this.manuallyAssignPassword = false;
      this.setUserAccount(null);//vuex mutation
      this.clearMessageAndErrors();//vuex mutation
      this.$bvModal.hide('m-user'); 
    },
    checkRouteChanged(){
      if(this.$route.params.org_id && this.$route.params.org_id != this.org_id){
        this.org_id = this.$route.params.org_id;
        this.load();
      }
    },
    confirmDeleteUser(){
      this.userDeleteConfirmed = true;
      setTimeout(()=>{
        this.userDeleteConfirmed = false;
      }, 5000);
    },
    confirmDeleteOrg(){
      this.confirmingOrgDelete = true;
      setTimeout(()=>{
        this.confirmingOrgDelete = false;
      }, 8000);
    },
    load(){
      if(this.org_id){
        this.loadOrg(this.org_id);
        this.searchUsers({org_id: this.org_id});
        this.searchPrograms({org_id: this.org_id});
      }
    },
    toggleEditing(){
      this.editing = !this.editing;
      if(this.editing){
        this.$bvModal.show('m-org');
      } else {
        this.$bvModal.hide('m-org');
      }
      
    },
    initUser(){
      if(!this.is_admin) return;  
      let user = {org_id: this.org.id};
      this.setUserAccount(user);//vuex mutation
      this.$bvModal.show('m-user');
    },
    selectUser(userView){
      if(!this.is_admin) return;
      if(!userView){
        userView = { org_id: this.org_id };
      }
      if(!userView.id) userView.id = userView.user_id;//from view
      this.setUserAccount(userView);//vuex mutation
      this.$bvModal.show('m-user');
    },
    async submitSaveOrg(){
      await this.saveOrg(this.org);
      if(!this.has_error){
        setTimeout(()=>{
          this.editing = !this.editing;
        }, 3000);
      }
    },
    async submitSaveUser(evt){
      evt.preventDefault();
      await this.saveUserAccount(this.user_account);
      if(!this.has_error){
        setTimeout(()=>{
          this.searchUsers({org_id: this.org_id});
          this.cancelUserEdit();
        }, 3000);
        
      }
    },
    async submitDeleteUser(evt){
      evt.preventDefault();
      await this.deleteUserAccount({org_id: this.org_id, id: this.user_account.user_id});
      if(!this.has_error){
        this.cancelUserEdit();
        this.searchUsers({org_id: this.org_id});
      } else {
        this.userDeleteConfirmed = false;
      }
    },
    createProgramModal(){
      this.setProgram({
        org_id: this.org.id,
        name: "",
      });
      this.$bvModal.show('progPanel');
    },
    async submitCreateProgram(evt){
      evt.preventDefault();
      await this.saveProgram(this.program);
      if(!this.has_error){
        setTimeout(()=>{
          this.$bvModal.hide('progPanel');
          this.searchPrograms({org_id: this.org_id});
        }, 3000);
      }
    },
    async submitDeleteOrg(){
      try{
        await this.deleteOrg(this.org_id);
        if(!this.has_error){
          this.$router.push('/orgs');
        }
      }catch(err){
        console.error(err);
      } finally {
        this.confirmingOrgDelete = false;
      }
    },
    selectProgram(){
      if(!this.programs || this.programs.length===0) {
        console.warn("No programs configured.");
        return;
      }
      let prog = this.programs[0];
      this.$router.push(`/programs/${prog.id}`);
    },
    
  },
}
</script>

<style scoped>
.selectablerow td:hover{
  cursor: pointer;
}
</style>