<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.border.rounded-lg.p-2
    div.text-center
      div.text-primary(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" @click="showModal" ) {{ asset_type }}
      div(v-if="asset")

        div.d-flex.justify-content-center
          span.mr-4 {{asset.name}}
          b-button(variant="outline-danger" size="sm" @click="remove")
            b-icon-trash
            span.ml-2 Remove

        b-img(:src="uploadsBaseUrl + asset.resource_key" fluid )
        //- div(v-if="image") Dimensions: {{ image.naturalWidth }} px wide  x  {{ image.naturalHeight}} px high
      div(v-else)
        span.text-secondary No {{asset_type}} yet.
      
    
  b-modal( title="Image Upload" :id="'m-asset-'+asset_type" )
    template(#modal-title)
      h5 Upload a {{asset_type}} image.
    
    div(v-if="asset_type==='logo'")
      p The logo is shown on your program's search result summary (i.e. the search results from a resident's search), and on your program's detailed information page. 
      p For optimal appearance, a 
        span.text-bold square image 
        span with a transparent background is preferred.
    div(v-if="asset_type==='banner'") 
      p The banner is displayed on your program's detailed information page. 
      p For optimal appearance, a 
        span.text-bold long banner image with an aspect ratio (i.e. width:height) between 8:1 and 10:1  
        span is preferred. The maximum displayed height of the banner is 200 pixels. If using a logo, transparent backgrounds are preferred.

    b-form-group(label="Upload an image to Use" label-cols="4" v-if="!fileData" )
      b-form-file#receipt(type="file" @change="fileProvided" :disabled="busy")
      small Common image formats are accepted.
    div(v-if="fileData && fileName && !uploadURL")
      span.text-secondary Selected file: 
        span.text-info {{ fileName }}
      p Select Upload to save the {{asset_type}}.
    
    template(#modal-footer="{cancel, hide}")
      b-button.float-left(size="sm" variant="secondary" @click="cancel()" ) Cancel
      b-button.float-right(size="sm" variant="primary" @click="uploadFile" :disabled="busy || !fileData") Upload
      
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
// import entity_widget from '@/mixins/entity-widget'
export default {
  name: 'ProgramAssetWidget',
  
  data(){
    return {
      hovered: false,
      asset: null,
      
      uploadsBaseUrl: process.env.VUE_APP_ASSET_BASE_URL,
      fileName: "", //the name of the file
      fileData: "", //the file data itself
      uploadURL: null, //signed url for the upload to s3
    };
  },
  props:{
    program: {required: true},
    asset_type: {type: String, required: true}
  },
  
  mixins:[
    // entity_widget
  ],

  computed:{
    ...mapGetters(['busy','error','message','has_error']),
    // async image(){
    //   if(this.asset){
    //     let img = new Image();
    //     img.src = this.uploadsBaseUrl + this.asset.resource_key;
    //     await img.decode();
    //     return img;
    //   } else {
    //     return null;
    //   }
    // },
   
  },

  async created(){
    await this.load();
  },

  methods:{
    ...mapActions(['saveProgramAsset','apiGet','apiPost','apiDelete']),
    ...mapMutations(['incrementInProcess','decrementInProcess']),
    async load(){
      let response = await this.apiGet({url: `/api/v1/org/${this.program.org_id}/program/${this.program.id}/asset`, parms: {asset_type: this.asset_type}});
      if(!this.has_error){
        if(response.items.length > 0){
          this.asset = response.items[0];
        } else {
          this.asset = null;
        }
      } else {
        this.asset = null;
      }
    },
    async save(payload){
      let response = await this.apiPost({url: `/api/v1/org/${this.program.org_id}/program/${this.program.id}/asset`, body: payload});
      if(!this.has_error){
        this.asset = response;
      } else {
        this.asset = null;
      }
    },
    async remove(){
      if(!this.asset) return;
      await this.apiDelete({url: `/api/v1/org/${this.program.org_id}/program/${this.program.id}/asset/${this.asset.id}`});
      await this.load();
    },
    showModal(){
      this.$bvModal.show('m-asset-'+this.asset_type);
    },
    handleHover(hoverState){
      this.hovered = hoverState;
    },
    // asset upload stuff...
    fileProvided(e){
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.parseImage(files[0])
    },
    parseImage(file){
      this.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        // let img = new Image();
        // img.src = e.target.result;
        // console.log('width: '+img.naturalWidth);
        // console.log('height: '+img.naturalHeight);

        if (!e.target.result.includes('data:image/jpeg') && 
          !e.target.result.includes('data:image/gif') && 
          !e.target.result.includes('data:image/png') ) {
          return alert('Wrong file type. Only gif, jpg, png files are supported.')
        }
        let maxMb = 10;
        if (e.target.result.length > 1024*1024*maxMb) {
          return alert(`Image is loo large - ${maxMb}Mb maximum`)
        }
        this.fileData = e.target.result
      }
      reader.readAsDataURL(file)
    },
    clearFileInfo(){
      this.fileName = '';
      this.fileData = '';
    },
    uploadFile: async function () {
      try{
        this.incrementInProcess();
        // Get the presigned URL
        let url = process.env.VUE_APP_ASSET_API_URL+`?program_id=${encodeURIComponent(this.program.id)}&filename=${encodeURIComponent(this.fileName)}`;
        const getSignedUrlResponse = await fetch(url,{
          method: 'GET',
        });
        let signedUrlPayload = await getSignedUrlResponse.json();

        // console.log('Response: ', signedUrlPayload)
        // console.log('Uploading: ', this.fileData)
        let splitImg =  this.fileData.split(',');
        let mimeType = splitImg[0].substring( splitImg[0].indexOf(':')+1, splitImg[0].indexOf(';') );
        let binary = atob(this.fileData.split(',')[1])
        let array = []
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i))
        }
        let blobData = new Blob([new Uint8Array(array)], {type: mimeType});
        
        console.log('Uploading to: ', signedUrlPayload.uploadURL)
        const uploadResponse = await fetch(signedUrlPayload.uploadURL, {
          method: 'PUT',
          body: blobData
        });
        if(uploadResponse.ok){
          
          //Initialize asset data
          let toSave = {
            program_id: this.program.id,
            asset_type: this.asset_type,
            resource_key: signedUrlPayload.file_path,
            name: this.fileName,
          };
          
          //Create the asset.
          await this.save(toSave);
          this.clearFileInfo();
          this.$bvModal.hide('m-asset-'+this.asset_type);
        } else {
          //Parse the error.
          let result = await uploadResponse.text();
          await this.setError(`Problem uploading file. ${result}`);
          console.error(result);
          
        }
      }catch(err){
        console.error(err);
      } finally {
        this.decrementInProcess();
      }
      
    },
  }//methods
}
</script>
<style scoped>

</style>