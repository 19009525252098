export default {
  computed: {
    // A program director
    is_director(){
      return this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("director");
    },
    // AOA user
    is_admin(){
      return this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("admin");
    },
    // Business Intelligence user
    is_bi(){
      return this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.includes("bi");
    }
    
  }
}
