<template lang="pug">
b-container
  b-form
    .d-flex.justify-content-between
      b-form-checkbox( v-if="my.index>0" v-model="my.logical" unchecked-value="OR" value="AND" variant="secondary" button size="sm" @change="observeChanges" ) {{my.logical}}
      b-form-select( v-model="my.alias" :options="alias_options" size="sm" @change="observeChanges" )
        template(#first)
          b-form-select-option(:value="null" disabled) choose
      //- 
      //- Program table
      //-
      template( v-if="alias_def.table==='t_program'")
        template( v-if="['number'].includes(alias_def.type)"  )
          b-form-select.operator-selector( v-model="my.operator" size="sm" @change="observeChanges")
            b-form-select-option(value="") 
            b-form-select-option(value="=") =
            b-form-select-option(value=">") &gt;
            b-form-select-option(value=">=") &gt;=
            b-form-select-option(value="<") &lt;
            b-form-select-option(value="<=") &lt;=
      
        template( v-else-if="['string'].includes(alias_def.type)" )
          b-form-select.operator-selector( v-model="my.operator" size="sm" @change="observeChanges" )
            b-form-select-option(value="") 
            b-form-select-option(value="=") =
            b-form-select-option(value="CONTAINS") contains
            b-form-select-option(value="STARTSWITH") starts with
            b-form-select-option(value="ENDSWITH") ends with
        
        template( v-else-if="['boolean'].includes(alias_def.type)" )
          b-form-select( v-model="my.value"  size="sm" @change="observeChanges" )
            b-form-select-option(:value="Boolean(true)") Yes
            b-form-select-option(:value="Boolean(false)") No

        template(v-else-if="['options'].includes(alias_def.type)")
          b-form-select( v-model="my.value" :options="value_options" size="sm" @change="observeChanges" )
            template(#first)
              b-form-select-option(:value="null" disabled) choose
      //- 
      //- Program Metric (View)
      //-
      template( v-if="alias_def.table==='v_program_metric'")
        //- special conditions for numeric program metric fields.
        //- category id chooser.
        b-form-select( v-model="my.category_option_id" :options="category_options" size="sm" @change="observeChanges" )
          template(#first)
            b-form-select-option(:value="null" disabled) choose
      
        //- value filter
        template(v-if="['number'].includes(alias_def.type)")
          b-form-select.operator-selector( v-model="my.operator" size="sm" @change="observeChanges")
            b-form-select-option(value="") 
            b-form-select-option(value="=") =
            b-form-select-option(value=">") &gt;
            b-form-select-option(value=">=") &gt;=
            b-form-select-option(value="<") &lt;
            b-form-select-option(value="<=") &lt;=

        
      //- 
      //- Program GEO Metric (View)
      //-
      template( v-if="alias_def.table==='v_program_geo_metric'" )
        //- choose by region
        b-form-select( v-model="my.geo_group_id" :options="geo_group_options" size="sm" @change="observeChanges" v-if="alias_def.by_region")
          template(#first)
            b-form-select-option(:value="null" disabled) choose
        
        //- otherwise choose by country and state
        template(v-if="!alias_def.by_region") 
          b-form-select(size="sm" v-model="my.country" :options="countries" @change="observeChanges" placeholder="country" )
          b-form-select(v-if="my.country=='USA'"      size="sm" v-model="my.state"  @change="observeChanges" :options="usa_states" )
          b-form-select(v-else-if="my.country=='CAN'" size="sm" v-model="my.state"  @change="observeChanges" :options="can_provinces" )
        
        //- value filter
        template(v-if="['number'].includes(alias_def.type)")
          b-form-select.operator-selector( v-model="my.operator" size="sm" @change="observeChanges"  style="max-width: 40px;")
            b-form-select-option(value="") 
            b-form-select-option(value="=") =
            b-form-select-option(value=">") &gt;
            b-form-select-option(value=">=") &gt;=
            b-form-select-option(value="<") &lt;
            b-form-select-option(value="<=") &lt;=

      //-
      //- value input (all tables)
      //-
      div( v-if="['number'].includes(alias_def.type)" )
        b-form-input.value-selector( v-model="my.value" size="sm" type="number" step="1" :debounce="1000" @change="observeChanges" )
      
      div( v-else-if="['string'].includes(alias_def.type)" )
        b-form-input.value-selector(v-model="my.value" size="sm" type="text" :debounce="1000" @change="observeChanges" )

      div.text-right.ml-2.mt-1
        b-link.text-danger( @click="remove" )
          b-icon-trash
    .row(v-if="error")
      .col.text-danger {{ error }}

</template>

<script>
import {mapGetters} from 'vuex'
import {countries, usa_states, can_provinces} from '@/mixins/geo'
const EVT_CHANGED="changed";
const EVT_REMOVED="removed";
export default {
  name: 'QueryCondition',

  props: {
    root: {type: Boolean, default: false, required: false},
    aliases: {type: Array},

    //initial state...
    expr: {required: true},

    
  },

  data(){
    return {
      error: null,
      my:{
        index: null,
        logical: 'AND',
        alias: '',
        operator: '=',
        
        metric_id: null, // only for program metrics
        category_id: null, // only for certain program metrics
        category_option_id: null,
        category_option_label: null,

        geo_metric_id: null, // only for program geo metrics
        geo_group_id: null,  // only for program geo metrics
        geo_group_option_label: null,
        country: null,
        state: null,

        value: null
      },

      countries,
      usa_states,
      can_provinces,
    };
  },

  computed: {
    ...mapGetters(['busy', 'category_defs', 'geography']),
    alias_def(){
      if(!this.aliases || !this.my.alias) return {}; 
      return this.aliases.find(f=>f.alias==this.my.alias);
    },
    alias_options(){
      return this.aliases.filter(a=>a.searchable).map(a=>{return {value:  a.alias, text: a.alias}; })
    },
    category_options(){
      if(this.alias_def.category_id){
        let category_def =  this.category_defs.find(cdef=>cdef.id==this.alias_def.category_id);
        if(category_def) return category_def.options;
      }
      return [];
    },
    category_option_label(){
      if(this.my.category_option_id){
        let option =  this.category_options.find(opt=>opt.value==this.my.category_option_id);
        if(option) return option.text;
      }
    },
    geo_group_options(){
      return this.geography.geo_groups.map(gg=>{
        return {
          text: gg.name,
          value: gg.id
        }
      });
    },
    geo_group_option_label(){
      if(this.my.geo_group_id){
        let option =  this.geo_group_options.find(opt=>opt.value==this.my.geo_group_id);
        if(option) return option.text;
      }
    },
    is_valid(){
      try{
        if(!this.my.alias) throw new Error('A field is required.');
        if(!this.alias_def) throw new Error(`"${this.my.alias}" is an unrecognized field.`);
        if(['number'].includes(this.alias_def.type) && (this.value_is_empty || this.my.value.trim() === "" ) ) throw new Error(`"${this.my.alias}" requires a value.`);
        if(['boolean','options','number','string'].includes(this.alias_def.type) && this.value_is_empty ) throw new Error(`"${this.my.alias}" requires a value.`);
        this.error = null;
        return true;
      }catch(err){
        this.error = err.message;
        return false
      }
    },
    value_is_empty(){
      return (typeof this.my.value) === 'undefined' || this.my.value === null;
    },
    value_options(){
      return this.alias_def && this.alias_def.type==='options' ? this.alias_def.options : null;
    },
  },

  created(){

    let initial = {
      logical: 'AND',
      alias: '',
      operator: '='
    };
    Object.assign(this.my, initial);
    Object.assign(this.my, this.expr);
    console.log(this.my);
    // this.my.index = this.index;
    // this.my.logical = this.logical || 'AND';
    // this.my.alias = this.alias || '';
    // this.my.operator = this.operator || '=';
    // this.my.value = this.value;
  },

  methods:{
    observeChanges(){
      this.my.metric_id = this.alias_def.metric_id;
      this.my.geo_metric_id = this.alias_def.geo_metric_id;

      if(this.is_valid) {
        this.my.category_id = this.alias_def.category_id;
        this.my.category_option_label = this.category_option_label;
        
        this.my.geo_group_option_label = this.geo_group_option_label;
        
        this.$emit(EVT_CHANGED, this.my)
      }
    },
    remove(){
      this.$emit(EVT_REMOVED, this.my.index);
    },
    
  },
}
</script>

<style scoped>
.operator-selector{
  min-width: 55px;
  max-width: 60px;
}
.value-selector{
  min-width: 55px;
  max-width: 60px;
}
</style>