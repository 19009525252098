<template lang="pug">
Base(:title="'\uD83C\uDF93 ' + (program ? program.name : 'Program')" :with_nav="true")
  template(#title)
    b-card-text.d-flex.justify-content-between
      div
        b-button(pill size="sm" variant="light" @click="$router.go(-1)" )
          b-icon-arrow-left-short
        span.ml-2 {{'\uD83C\uDF93 ' + (program ? program.name : 'Program')}}
      div
        StatusWidget
        .d-flex.justify-content-center(v-if="program_progress && !busy" )
          //- span.mr-1 Profile Complete: 
          //- b-progress.mt-1(:max="program_progress.available"  show-progress style="width: 20em;")
          //-   b-progress-bar(:value="program_progress.count" :label="Number(program_progress.count/program_progress.available).toLocaleString(undefined, {style:'percent'})")
      div.d-flex.justify-content-end
          b-form-radio-group(size="sm" v-if="program" v-model="program.status" inline)
            b-form-radio(value="private") Private
            b-form-radio(value="published") Published
          b-button(size="sm" variant="primary" @click="submitSave" v-if="program" :disabled="busy") Save

  b-tabs( pills vertical small v-if="program_reloaded && program" id="pgm")
    b-tab(href="#" title="Overview" )
      b-container.p-0(style="max-height: 200px; overflow: hidden;")
        b-img.float-left(v-if="program_banner_url" :src="program_banner_url" fluid-grow )
      .row
        .col
          EntityTextField( :entity="program" property_name="name" label="Program:")
          
          EntityDropdownField( :entity="program" property_name="program_type" label="Program Type:" :options="program_type_options")
          EntityTextField( :entity="program" property_name="chairman" label="Chairperson:")
          EntityTextField( :entity="program" property_name="program_director" label="Program Director:")
          EntityTextField( :entity="program" property_name="associate_program_director" label="Associate Program Director:")
          EntityTextField( :entity="program" property_name="program_coordinator" label="Program Coordinator:")
          
          
          EntityNumberField( :entity="program" property_name="residents_total" label="Total Residents in Program:" :min="0" :max="100" :step="1")
          EntityDropdownField( :entity="program" property_name="length_of_training" label="Length of Training:" :options="length_of_training_options")
          EntityTextField( :entity="program" property_name="main_institution" label="Main Institution:")
          EntityNumberField( :entity="program" property_name="weeks_dedicated_research_time" label="Dedicated Research Time:" :min="0" :max="26" :step="1" unit="weeks")
          EntityNumberField( :entity="program" property_name="trauma_level" label="Trauma Level:" :min="0" :max="6" :step="1" unit="level" prepend)
      
          EntityNumberField( :entity="program" property_name="attendings_total" label="Number of Attendings:" :min="0" :max="100" :step="1" )
          EntityNumberField( :entity="program" property_name="attendings_minority" label="Number of URiM Attendings:" :min="0" :max="100" :step="1" :denominator="program.attendings_total" )
          EntityNumberField( :entity="program" property_name="attendings_female" label="Number of Female Attendings:" :min="0" :max="100" :step="1" :denominator="program.attendings_total")
          
          EntityBooleanField(:entity="program" property_name="diversity_initiatives" label="Participates in Diversity Initiatives" :tf_labels="['yes','no']" )
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="diversity_initiative_info" label="Diversity Initiative Information:" :rows="2" :max_rows="5" :max_chars="1000")
          
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="mission_statement" label="Mission Statement:" :rows="2" :max_rows="5" :max_chars="2000" )
          
          
        .col
          EntityNumberField( :entity="program" property_name="five_year_abos_part1_pass_rate" label="5 year Orthopaedic Surgery Board Pass Rate:" :min="0" :max="100" :step="1" unit="%")
          
          ProgramMetricWidget( 
            :program_id="program.id" 
            :metric="met('fellows_subspecialty')"
            :values="metv('fellows_subspecialty')"
            :category_options="catopts('subspecialty')" 
            label="Number of Fellows:"
            empty_value="0"
            @pm-reload="submitLoadProgramMetrics" )

          
          ProgramMetricWidget( 
            :program_id="program.id" 
            :metric="met('resident_subspecialty')"
            :values="metv('resident_subspecialty')"
            :category_options="catopts('subspecialty')" 
            label="Fellowship Subspecialty Selection (past 5 years):"
            empty_value="General"
            @pm-reload="submitLoadProgramMetrics" )

          ProgramMetricWidget( 
            :program_id="program.id" 
            :metric="met('highlights')"
            :values="metv('highlights')"
            label="Program Highlights (List 5):"
            @pm-reload="submitLoadProgramMetrics" )
            
          
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="hospital_affiliations" label="Hospital Affiliations:" :rows="2" :max_rows="5" :max_chars="1000")
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="external_rotation" label="External Rotation:" :rows="2" :max_rows="5" :max_chars="500")
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="didactics_structure" label="Structure of Didactics:" :rows="2" :max_rows="5" :max_chars="500")
          
    
    b-tab(href="#" title="Current Residents" )
      .row
        .col
          //- EntityNumberField( :entity="program" property_name="step1_median" label="Median USMLE Step 1:" :min="192" :max="300" )
          EntityNumberField( :entity="program" property_name="step2_median" label="Median USMLE Step 2:" :min="200" :max="300" )

          EntityNumberField( :entity="program" property_name="comlex_usa_level1_median" label="Median COMLEX-USA Level 1:" :min="9" :max="999" )
          EntityNumberField( :entity="program" property_name="comlex_usa_level2_median" label="Median COMLEX-USA Level 2:" :min="9" :max="999" )

          EntityNumberField( :entity="program" property_name="residents_minority" label="Number of URiM Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( :entity="program" property_name="residents_female" label="Number of Female Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( :entity="program" property_name="residents_associated_school" label="Residents from Associated Medical School:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( :entity="program" property_name="residents_elective_rotation" label="Residents that completed an Elective Rotation:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )

          EntityNumberField( :entity="program" property_name="residents_aoa_status" label="Number of Residents Achieved AOA Status:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( :entity="program" property_name="residents_d_o" label="Number of D.O. Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total")
          EntityNumberField( :entity="program" property_name="residents_intl_graduated" label="Number of Residents Internationally Graduated:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total"  )
          EntityNumberField( :entity="program" property_name="residents_unmatched_initially" label="Number of Residents Unsuccessful Match into Orthopaedics on Initial Attempt:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          
        .col
        
          ProgramGeographicMetricWidget(
            label="Percentage of Residents by Medical School Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('resident_med_school_region_pct')"
            :values="gmetv('resident_med_school_region_pct')"
            catch_all_label="International"
            @pm-reload="submitLoadProgramGeoMetrics" )
          
          ProgramGeographicMetricWidget(
            label="Percentage of Residents by Hometown Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('resident_hometown_region_pct')"
            :values="gmetv('resident_hometown_region_pct')"
            catch_all_label="International"
            @pm-reload="submitLoadProgramGeoMetrics" )
        
    b-tab(href="#" title="Program Interview" )
      .row
        .col
          EntityNumberField(:entity="program" :is_editing="editing" property_name="avg_yearly_applications" label="Average Number of Applications per Year:" :min="0" :max="2500" )
          EntityNumberField(:entity="program" :is_editing="editing" property_name="total_yearly_interviews" label="Average Number of Interviews:" :min="0" :max="999" hint="in a typical year")
          EntityNumberField(:entity="program" :is_editing="editing" property_name="yearly_non_rotator_interviews" label="Non-Rotator Interviews Granted:" :min="0" :max="999" hint="in the previous year" )
          EntityNumberField(:entity="program" :is_editing="editing" property_name="yearly_rotator_interviews" label="Rotator Interviews Granted:" :min="0" :max="999" hint="in the previous year" )
          EntityNumberField(:entity="program" :is_editing="editing" property_name="avg_away_rotators" label="Average Number of Away Rotators:" :min="0" :max="999" )
        
        .col
          EntityTextField(:entity="program" property_name="months_available_away_rotations" label="Months Available for Away Rotation:" )
          EntityNumberField(:entity="program" property_name="avg_monthly_rotators" label="Average Number of Away Rotators Per Month:" :min="0" :max="999" )
          EntityBooleanField(:entity="program" property_name="rotators_interviewed_during_away_rotation" label="Rotators Interviewed During the Away Rotation:" :tf_labels="['yes','no']" )
        


    b-tab(href="#" title="Application Process" )
      b-row
        .col
          EntityBooleanField( :entity="program" property_name="use_privatized_scoring_system" label="Use of Privatized Scoring System:" :tf_labels="['yes','no']" )
          EntityBooleanField( :entity="program" property_name="use_interview_screening_tools" label="Use of Interview Screening Tools:" :tf_labels="['yes','no']" )
          
          //- EntityTextField( :entity="program" property_name="step1_cutoff_score_rotators" label="USMLE Step 1 Cutoff Score - Rotators:" )
          //- EntityTextField( :entity="program" property_name="step1_cutoff_score_non_rotators" label="USMLE Step 1 Cutoff Score - Non-Rotators:" )
          EntityTextField( :entity="program" property_name="step2_cutoff_score_rotators" label="USMLE Step 2 Cutoff Score - Rotators:" )
          EntityTextField( :entity="program" property_name="step2_cutoff_score_non_rotators" label="USMLE Step 2 Cutoff Score - Non-Rotators:" )

          EntityTextField( :entity="program" property_name="comlex_usa_level1_cutoff_rotators" label="COMLEX-USA Level 1 Cutoff Score - Rotators:" )
          EntityTextField( :entity="program" property_name="comlex_usa_level1_cutoff_non_rotators" label="COMLEX-USA Level 1 Cutoff Score - Non-Rotators:" )
          EntityTextField( :entity="program" property_name="comlex_usa_level2_cutoff_rotators" label="COMLEX-USA Level 2 Cutoff Score - Rotators:" )
          EntityTextField( :entity="program" property_name="comlex_usa_level2_cutoff_non_rotators" label="COMLEX-USA Level 2 Cutoff Score - Non-Rotators:" )

        .col
          EntityTextField( :entity="program" property_name="clerkship_grade_criteria_year4" label="4th Year Clerkship Grades Criteria:" )
          EntityTextField( :entity="program" property_name="clerkship_grade_criteria_year3" label="3rd Year Clerkship Grades Criteria:" )
          EntityBooleanField( :entity="program" property_name="aoa_status_required" label="AOA Status Required:" :tf_labels="['yes','no']" )
          EntityTextField( :entity="program" property_name="graduates_considered_d_o" label="D.O. Graduates Considered:" hint="Please elaborate." )
          EntityTextField( :entity="program" property_name="graduates_considered_intl" label="International Graduates Considered:" hint="Please elaborate." )
          EntityBooleanField(:entity="program" property_name="department_chair_lor_required" label="Department Chair Letter of Recommendation Required" :tf_labels="['yes','no']" )
          
      b-row
        b-col
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="looking_for_verbiage" label="What are you looking for in an applicant? (100 Words):" :rows="2" :max_rows="10" :max_chars="2500")
          
    b-tab(href="#" title="Rank List Data" )
      b-row
        .col
          //- EntityTextField( :entity="program" property_name="rlist_median_step1_narrative" label="Median USMLE Step 1 Score:" hint="Example: 247 (Range 225-268)")
          EntityTextField( :entity="program" property_name="rlist_median_step2_narrative" label="Median USMLE Step 2 Score:" hint="Example: 254 (Range 227-272)" )
          EntityTextField( :entity="program" property_name="rlist_median_comlex_usa_level1_narrative" label="Median COMLEX-USA Level 1 Score:" hint="Example: 801 (Range 770-820)")
          EntityTextField( :entity="program" property_name="rlist_median_comlex_usa_level2_narrative" label="Median COMLEX-USA Level 2 Score:" hint="Example: 822 (Range 785-840)" )
          EntityNumberField( :entity="program" property_name="rlist_minority" label="URiM Ranked Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( :entity="program" property_name="rlist_female" label="Female Ranked Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( :entity="program" property_name="rlist_aoa_status" label="Ranked Applicants with AOA Status:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( :entity="program" property_name="rlist_top_10pct_med_school" label="Ranked Applicants within top 10% of Medical School Class:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( :entity="program" property_name="rlist_d_o" label="Ranked D.O. Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( :entity="program" property_name="rlist_international" label="Ranked International Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
        
        .col
          ProgramGeographicMetricWidget(
            label="Percentage of Ranked Applicants by Medical School Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('rlist_med_school_region_pct')"
            :values="gmetv('rlist_med_school_region_pct')"
            catch_all_label="International"
            @pm-reload="submitLoadProgramGeoMetrics" )

          ProgramGeographicMetricWidget(
            label="Percentage of Ranked Applicants by Hometown Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('rlist_hometown_region_pct')"
            :values="gmetv('rlist_hometown_region_pct')"
            catch_all_label="International"
            @pm-reload="submitLoadProgramGeoMetrics" )


    b-tab(href="#" title="Contact Info" )
      .row
        .col
          EntityAddressInput( :entity="program" label="Address" )
          EntityTextField( :entity="program" property_name="phone" label="Phone")
          EntityTextField( :entity="program" property_name="fax" label="Fax")
      
        .col
          EntityTextField( :entity="program" property_name="residents_website" label="Current Residents website:" display_type="url")
          EntityTextField( :entity="program" property_name="away_rotation_website" label="Away rotation information website:" display_type="url")
          EntityTextField( :entity="program" property_name="facebook_url" label="Facebook" display_type="url")
          EntityTextField( :entity="program" property_name="instagram_url" label="Instagram" display_type="url")
          EntityTextField( :entity="program" property_name="linkedin_url" label="LinkedIn" display_type="url")
          EntityTextField( :entity="program" property_name="twitter_url" label="Twitter" display_type="url")
    
    b-tab(href="#")
      template(#title)
        .text-left.callout Current Season
      .row
        .col
          EntityDropdownField(:entity="program" property_name="virtual_interviews" label="Virtual interviews available?"  :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'TBD'}]")
          EntityTextField(:entity="program" property_name="virtual_interview_dates" label="Virtual interview dates:" v-show="program.virtual_interviews==='yes'")
          EntityDropdownField(:entity="program" property_name="in_person_interviews" label="In-person interviews available?"  :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'TBD'}]")
          EntityTextField(:entity="program" property_name="in_person_interview_dates" label="In-person interview dates:" v-show="program.in_person_interviews==='yes'")

          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="additional_interview_info" label="Additional interview information:" :rows="2" :max_rows="5" :max_chars="1000" 
            hint="Include specific explanation here to clarify your plans for interviews. Especially if in-person and virtual interviews are TBD, if your interview dates have been chosen, include those dates here." )
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="virtual_opportunities" label="Virtual opportunities:" :rows="2" :max_rows="5" :max_chars="1000")

        .col
          EntityDropdownField(:entity="program" property_name="open_house" label="Open house session available?" :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" :label_cols="8")
          EntityDropdownField(:entity="program" property_name="in_person_open_house" label="In-person open house?" :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" v-show="program.open_house" :label_cols="8")
          EntityTextField(:entity="program" property_name="in_person_open_house_dates" label="In-person open house dates:" v-show="program.open_house && program.in_person_open_house" )
          EntityDropdownField(:entity="program" property_name="virtual_open_house" label="Virtual open house?" :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" v-show="program.open_house")
          EntityTextField(:entity="program" property_name="virtual_open_house_dates" label="Virtual open house dates:" v-show="program.open_house && program.virtual_open_house")
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="additional_open_house_info" label="Additional open house information:" :rows="2" :max_rows="5" :max_chars="1000"  v-show="program.open_house" )
          
          EntityBooleanField(:entity="program" property_name="offer_day_aoa_cord_uod" label="Participating in AOA/CORD Universal Interview Offer Day?" :tf_labels="['yes','no']" :label_cols="8")
          EntityTextField(:entity="program" property_name="offer_day_interview_date" label="If not participating in AOA/CORD Universal Offer Day, what day(s) will you offer interviews?" v-show="!program.offer_day_aoa_cord_uod")
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="additional_offer_day_info" label="Additional offer day information:" :rows="2" :max_rows="5" :max_chars="1000")
          
          EntityBooleanField(:entity="program" property_name="aamc_eras_supp_application" label="Participating in AAMC ERAS Preference Signaling" :tf_labels="['yes','no']" :label_cols="8")

    b-tab(href="#")
      template(#title)
        .text-left Research Opportunities
      
      .row
        .col
          EntityBooleanField(:entity="program" property_name="gap_year_research" label="Gap year option" :tf_labels="['yes','no']" )
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="gap_year_research_info" label="Gap year information:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.gap_year_research")
        .col
          EntityBooleanField(:entity="program" property_name="postgrad_research" label="Post graduation option" :tf_labels="['yes','no']" )
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="postgrad_research_info" label="Post-graduation information:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.postgrad_research")
        .col
          EntityBooleanField(:entity="program" property_name="salaried" label="Is salary provided?" :tf_labels="['yes','no']" )
      .row
        .col
          ProgramTextWidget( :org_id="program.org_id" :program_id="program.id" alias="research_contact_info" label="Contact information for research opportunity:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.gap_year_research || program.postgrad_research")
  
    b-tab(href="#" title="Images" )

      ProgramAssetWidget(:program="program" asset_type="logo")

      ProgramAssetWidget(:program="program" asset_type="banner")
  //- .row
  //-   .col-3
  //-     b-list-group
  //-       b-list-group-item(href="#" @click="") Overview
  //-       b-list-group-item(href="#" @click="") Current Residents
  //-       b-list-group-item(href="#" @click="") Program Interview
  //-       b-list-group-item(href="#" @click="") Application Process
  //-       b-list-group-item(href="#" @click="") Rank List Data
  //-       b-list-group-item(href="#" @click="") Resources

  //-   .col-9

</template>

<script>
import Base from '@/components/Base.vue'
import EntityAddressInput from '@/components/EntityAddressInput.vue'
import EntityBooleanField from '@/components/EntityBooleanField.vue'
import EntityDateField from '@/components/EntityDateField.vue'
import EntityDropdownField from '@/components/EntityDropdownField.vue'
import EntityNumberField from '@/components/EntityNumberField.vue'
import EntityTextField from '@/components/EntityTextField.vue'
import EntityTextareaField from '@/components/EntityTextareaField.vue'
import ProgramAssetWidget from '@/components/ProgramAssetWidget.vue'
import ProgramGeographicMetricWidget from '@/components/ProgramGeographicMetricWidget.vue'
import ProgramMetricWidget from '@/components/ProgramMetricWidget.vue'
import ProgramTextWidget from '@/components/ProgramTextWidget.vue'
import StatusWidget from '@/components/StatusWidget.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import baseMix from '@/mixins/base'
import ssvalidation from '@/mixins/ss-validation'
export default {
  name: 'EditProgram',

  mixins: [baseMix, ssvalidation],
  
  components: {
    Base,
    EntityAddressInput,
    EntityBooleanField,
    EntityDateField,
    EntityDropdownField,
    EntityNumberField,
    EntityTextField,
    EntityTextareaField,
    ProgramAssetWidget,
    ProgramGeographicMetricWidget,
    ProgramMetricWidget,
    ProgramTextWidget,
    StatusWidget,
  },
  
  computed: {
    ...mapGetters(['org','category_defs','geography','metric_defs','program','program_assets','program_metrics','program_texts','program_geo_metrics','program_progress',]),
    length_of_training_options(){
      if(!this.constants) return [];
      return this.constants.program.length_of_training.map(x => { return {text: x, value: x}; } );
    },

    program_type_options(){
      if(!this.constants) return [];
      return this.constants.program.program_types.map(x => { return {text: x, value: x}; } );
    },

    program_progress_pct(){
      if(this.program_progress){
        return Math.round( this.program_progress.count/this.program_progress.available * 100)
      } else {
        return 0;
      }
      
    },

    program_banner_url(){
      if(!this.program_assets) return "";
      let asset = this.program_assets.find(a=>a.asset_type==='banner');
      if(!asset) return "";
      return process.env.VUE_APP_ASSET_BASE_URL + asset.resource_key;
    },

  },

  data(){
    return {
      editing: false,
      program_reloaded: false,
    };
  },

  created(){
    this.load()
  },

  methods: {
    ...mapActions(['loadProgram','saveProgram','loadGeos','loadMetrics','loadMetrics','loadProgramAssets','loadProgramMetrics','loadProgramGeoMetrics','getProgramProgress']),
    ...mapMutations(['setError']),
    catopts(alias){
      if(!alias || !this.category_defs) return [];
      let cat = this.category_defs.find(c=>c.alias===alias);
      return cat ? cat.options : [];
    },

    gmet(alias){
      if(!alias || !this.metric_defs) return null;
      return this.metric_defs.geo_metrics.find(gm=>gm.alias===alias);
    },

    gmetv(alias){
      let gmet = this.gmet(alias);
      if(gmet) return this.program_geo_metrics.filter(pgm=>pgm.geo_metric_id==gmet.id);
      return null;
    },

    met(alias){
      if(!alias || !this.metric_defs) return null;
      return this.metric_defs.metrics.find(m=>m.alias===alias);
    },

    metv(alias){
      let met = this.met(alias);
      if(met) return this.program_metrics.filter(pm=>pm.metric_id==met.id);
      return null;
    },
    

    async load(){
      await this.loadProgram({org_id: this.org.id, id: this.$route.params.program_id});
      this.program_reloaded = true;//when admins switch programs
      if(this.program){
        await Promise.all([
          this.submitLoadProgramMetrics(),
          this.submitLoadProgramGeoMetrics(),
          this.getProgramProgress({program_id: this.program.id}),
          this.loadProgramAssets({org_id: this.org.id, program_id: this.$route.params.program_id})
        ]);
      }
    },

    toggleEditing(){
      this.editing = !this.editing;
    },
    async submitSave(){
      await this.saveProgram(this.program);
      if(!this.has_error){
        this.editing = false;
        await this.getProgramProgress({program_id: this.program.id})
      } else {
        if(this.errors){
          let allErrors = this.error + ' Details: ' + this.errors.map(e=>e.error).join('. ')
          this.setError(allErrors);
        }
      }
    },
    async submitLoadProgramMetrics(){
      let search = {org_id: this.org.id, program_id: this.program.id};
      this.loadProgramMetrics(search);
    },
    async submitLoadProgramGeoMetrics(){
      let search = {org_id: this.org.id, program_id: this.program.id};
      this.loadProgramGeoMetrics(search);
    },
  },
  
}
</script>

<style scoped>
.ilbl{
  font-size: .7em;
}
input, input[type=number], select, option, textarea {
  font-size: .7em;
}
.input-group-append, .input-group-prepend {
  font-size: .7em;
  height: 1.7rem;
}
.callout{
  font-weight: bolder;
  color: #794039;
}
</style>