// Layouts
import AuthLayout from '../AuthLayout.vue'
import PublicLayout from '../PublicLayout.vue'
import PublicMobileLayout from '../PublicMobileLayout.vue'

// Views

import AdminHome from '../views/AdminHome.vue'
import Login from '../views/Login.vue'
import ManageMetrics from '../views/ManageMetrics.vue'
import ManageOrgs from '../views/ManageOrgs.vue'
import ViewOrg from '../views/ViewOrg.vue'
import EditProgram from '../views/EditProgram.vue'
import ViewProgramDetails from '../views/ViewProgramDetails.vue'
import ViewProgramDetailsMobile from '../views/ViewProgramDetailsMobile.vue'
import Password from '../views/Password.vue'
import QueryBrowser from '../views/QueryBrowser.vue'
import Search from '../views/Search.vue'
import MobileSearch from '../views/MobileSearch.vue'
import Unauthorized from '../views/Unauthorized.vue'

const routes = [

  {
    path: '/admin',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'AdminHome',
        component: AdminHome
      }
    ]
  },
  {
    path: '/login',
    component: publicLayout(),
    children:[
      {
        path: '',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/metrics',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'ManageMetrics',
        component: ManageMetrics
      }
    ]
  },
  {
    path: '/orgs',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'ManageOrgs',
        component: ManageOrgs
      }
    ]
  },
  {
    path: '/orgs/:org_id',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'ViewOrg',
        component: ViewOrg
      }
    ]
  },
  {
    path: '/programs/:program_id',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'EditProgram',
        component: EditProgram
      }
    ]
  },
  {
    path: '/qbrowser',
    component: authLayout(),
    children:[
      {
        path: '',
        name: 'QueryBrowser',
        component: QueryBrowser
      }
    ]
  },
  {
    path: '/password/:token',
    component: publicLayout(),
    children:[
      {
        path: '',
        name: 'Password',
        component: Password
      }
    ]
  },
  {
    path: '/search',
    component: publicLayout(),
    children: searchChildren()
  },
  {
    path: '/program-details/:program_id',
    component: publicLayout(),
    children: searchDetailsChildren()
  },
  {
    path: '/unauthorized',
    component: publicLayout(),
    children:[
      {
        path: '',
        name: 'Unauthorized',
        component: Unauthorized
      }
    ]
  },
]

function isMobile() {
  // return screen.width <= 760;
  return window.matchMedia("only screen and (max-width: 760px)").matches;
}
function publicLayout(){
  if(isMobile()){
    return PublicMobileLayout;
  } else {
    return PublicLayout;
  }
}
function authLayout(){
  return AuthLayout;
}
function searchChildren(){
  if(isMobile()){
    return [
      {
        path: '',
        name: 'MobileSearch',
        component: MobileSearch
      }
    ];
  } else {
    return [
      {
        path: '',
        name: 'Search',
        component: Search
      }
    ];
  }
}
function searchDetailsChildren(){
  if(isMobile()){
    return [
      {
        path: '',
        name: 'ViewProgramDetailsMobile',
        component: ViewProgramDetailsMobile
      }
    ];
  } else {
    return [
      {
        path: '',
        name: 'ViewProgramDetails',
        component: ViewProgramDetails
      }
    ];
  }
}

export default routes
