<template lang="pug">
div( style="height:100px;" )
  label.col-form-label-sm.mb-0.pb-0 {{label}}
  div.pl-3.pr-3(style="margin-top: 2.2em;")
    div(ref="slider")
  //- .d-flex.justify-content-between
  //-   small {{min}}
  //-   span.text-info {{value_label}}
  //-   small {{max}}
  //- b-form-group(:label="label" :label-cols="label_cols" )
  //- b-form-input(v-model="filter[filter_property]" type="range" :min="min" :max="max" :step="step" @update="onUpdate" debounce="500")
  //- .d-flex.justify-content-between
  //-   small {{min}}
  //-   span.text-info {{value_label}}
  //-   small {{max}}
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import * as noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css';
export default {
  name: 'SearchRangeField',
  
  props:{
    filter: {type: Object, required: true},
    filter_property: {type: String, required: true},
    filter_property_max: {type: String, required: true},
    label: {type: String},
    label_cols:  {type: Number},
    min: {type: Number, required: true, default: 0},
    max: {type: Number, required: true, default: 999},
    step: {type: Number, default: 1},
  },
  
  data(){
    return {
      localValue: null,
      localValueMax: null,
    }
  },
  
  mounted(){
    // let major =  Math.round((this.max - this.min) / 4);
    // let minor = Math.round(major/4) > 0 ? Math.round(major/4) : 1;
    let slider = noUiSlider.create(this.$refs.slider, {
      start: [this.min, this.max],
      connect: true,
      step: 1,
      range: {
        'min': this.min,
        'max': this.max
      },
      pips: {
        mode: 'position',
        density: 5
      },
      tooltips:  {
        to: (v)=>{
          return Math.round(v);
        },
        from: (v)=>{
          return Math.round(v);
        },
      }
         
    });
    slider.on('change', this.onChanged);
  },


  methods:{
    reset(){
      this.localValue = null;
      this.localValueMax = null;
      this.$refs.slider.noUiSlider.reset();
    },

    onChanged(vals){
      this.localValue = vals[0];
      this.localValueMax = vals[1];
      let payload = [];
      payload.push({name: this.filter_property, value: this.localValue});
      payload.push({name: this.filter_property_max, value: this.localValueMax});
      this.$emit('update-range', payload);

    },
  },
  
}
</script>
<style >
.noUi-connect {
  background: #547179;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background: #fff;
  color: #547179;
  padding: 0px 3px 0px 3px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
</style>