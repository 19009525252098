<template lang="pug">
b-container.text-dark(fluid).p-0
  b-form-row
    .col-1
      b-link.text-danger(@click="submitDelete" href="#" :disabled="busy")
        b-icon-x-circle.mt-1
    .col
      b-form-select(size="sm" :options="category_options" v-model="program_metric.category_option_id" style="padding: 1px 1px 1px 4px;" @change="markChanged" v-if="!program_metric.id" :disabled="busy")
      b-form-input(size="sm" type="text" readonly v-model="program_metric.category_option_label" v-else-if="program_metric.id") 
    .col-3
      b-form-input(size="sm" :type="metric_input_type" v-model="program_metric.value" @change="markChanged" :disabled="busy")
    b-col(cols="2" v-if="changed")
      b-link(href="#" @click="submitSave" :disabled="busy")
        span.text-success save
        
 
</template>

<script>
import ssvalidation from '@/mixins/ss-validation'
import {mapGetters} from 'vuex'
export default {
  name: 'PMCategoryInput',
  
  components:{},
  
  mixins:[
    ssvalidation
  ],

  props:{
    category_options: {type: Array, required: false},
    program_metric: {type: Object, required: true},
    metric: {type: Object, required: true},
  },


  data(){
    return {
      confirming: false,
      changed: false,
    };
  },

  computed: {
    ...mapGetters(['busy','org','program','has_error']),
    metric_input_type(){
      if(!this.metric || !this.metric.data_type) return "text";
      if(this.metric.data_type==="integer") return "number";
      return "text";
    },
  },

  // watch:{
  //   program_metric(newv, oldv){
  //     this.changed = oldv.value != newv.value || oldv.category_option_id != newv.category_option_id;
  //   }
  // },

  methods: {
    markChanged(){
      this.changed = true;
    },

    async submitSave(){
      let payload = this.program_metric;
      payload.org_id = this.org.id;
      await this.$store.dispatch("saveProgramMetric", payload);
      if(!this.has_error){
        this.$emit("pm-saved");
        this.changed = false;
      }
    },

    async submitDelete(){
      //assumption is VUEX org and program are available in enclosing view
      let payload = {
        org_id: this.org.id, 
        program_id: this.program.id, 
        id: this.program_metric.id
      };
      await this.$store.dispatch("deleteProgramMetric", payload);
      if(!this.has_error){
        this.changed = false;
        this.$emit("pm-removed");
      }
    }

  },


}
</script>
<style scoped>
input, input[type=number], select, option {
  font-size: .9em;
}
</style>