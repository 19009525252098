import Vue from 'vue'
import Vuex from 'vuex'
import {callApi, ApiError, ApiNotAvailableError, ApiAuthorizationError } from '../lib/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aliases: null, //global
    category_defs: [], //global
    constants: null,//global
    geo: null,
    geos: [],
    geography: null,//global
    org: null,
    orgs: [],
    metric_defs: null,//global
    metric: null,
    metrics: [],
    metric_option: null,
    metric_options: [],
    program: null,
    program_progress: null,
    programs: [],
    program_asset: null,
    program_assets: [],
    program_geo_metric: null,
    program_geo_metrics: [],
    program_metric: null,
    program_metrics: [],
    program_text: null,
    program_texts: [],
    region: null,
    regions: [],
    total_count: 0,
    users: [],
    user_account: null, //because user is current login

    auth_error_count: 0,
    dirty: false,
    error: null,
    errors: null,
    global_error: null,
    in_process: 0,
    jwt: null,
    message: null,
    realm_id: null,
    user: null,
  },
  mutations: {
    clearMessageAndErrors(state){
      state.global_error = null;
      state.message = null;
      state.error = null;
      state.errors = null;
    },
    incrementAuthErrorCount(state){
      if(state.auth_error_count === null) state.auth_error_count=0;
      state.auth_error_count++;
    },
    incrementInProcess(state){
      state.in_process++;
    },
    decrementInProcess(state){
      state.in_process--;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setAliases(state, aliases){
      state.aliases = aliases;
    },
    setAuthErrorCount(state, auth_error_count){
      state.auth_error_count = auth_error_count;
    },
    setCategoryDefs(state, category_defs){
      state.category_defs = category_defs;
    },
    setConstants(state, constants){
      state.constants = constants;
    },
    setDirty(state, dirty){
      state.dirty = dirty;
    },
    setError(state, error){
      state.error = error;
    },
    setErrors(state, errors){
      state.errors = errors;
    },
    setGeo(state, geo) {
      state.geo = geo;
    },
    setGeos(state, geos) {
      state.geos = geos;
    },
    setGeography(state, geography) {
      state.geography = geography;
    },
    setGlobalError(state, global_error){
      state.global_error = global_error;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    setMessage(state, message){
      state.message = message;
    },
    setMetric(state, metric) {
      state.metric = metric;
    },
    setMetricDefs(state, metric_defs) {
      state.metric_defs = metric_defs;
    },
    setMetrics(state, metrics) {
      state.metrics = metrics;
    },
    setMetricOption(state, metric_option) {
      state.metric_option = metric_option;
    },
    setMetricOptions(state, metric_options) {
      state.metric_options = metric_options;
    },
    setProgram(state, program){
      state.program = program;
    },
    setProgramProgress(state, program_progress){
      state.program_progress = program_progress;
    },
    setPrograms(state, programs){
      state.programs = programs;
    },
    setProgramAsset(state, program_asset){
      state.program_asset = program_asset;
    },
    setProgramAssets(state, program_assets){
      state.program_assets = program_assets;
    },
    setProgramGeoMetric(state, program_geo_metric){
      state.program_geo_metric = program_geo_metric;
    },
    setProgramGeoMetrics(state, program_geo_metrics){
      state.program_geo_metrics = program_geo_metrics;
    },
    setProgramMetric(state, program_metric){
      state.program_metric = program_metric;
    },
    setProgramMetrics(state, program_metrics){
      state.program_metrics = program_metrics;
    },
    setProgramText(state, program_text){
      state.program_text = program_text;
    },
    setProgramTexts(state, program_texts){
      state.program_texts = program_texts;
    },
    setRealmId(state, realm_id) {
      state.realm_id = realm_id;
    },
    setOrg(state, org){
      state.org = org;
    },
    setOrgs(state, orgs){
      state.orgs = orgs;
    },
    setTotalCount(state, total_count){
      state.total_count = total_count;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserAccount(state, user_account) {
      state.user_account = user_account;
    },
    setUsers(state, users) {
      state.users = users;
    },
   
  },
  getters: {
    account: state => state.account,
    aliases: state => state.aliases,
    auth_error_count: state => state.auth_error_count,
    busy: state => state.in_process>0,
    category_defs: state => state.category_defs,
    constants: state => state.constants,
    dirty: state => state.dirty,
    error: state => state.error,
    errors: state => state.errors,
    geo: state => state.geo,
    geos: state => state.geos,
    geography: state => state.geography,
    global_error: state => state.global_error,
    has_error: state => (state.error || state.auth_error_count>0) ? true : false,
    has_global_error: state => state.global_error ? true : false,
    has_message: state => state.message ? true : false,
    in_process: state => state.in_process,
    is_admin: state => state.user && state.user.roles && state.user.roles.includes("admin"),
    is_client: state => state.user && state.user.roles && state.user.roles.includes("client"),
    jwt: state => state.jwt,
    message: state => state.message,
    metric: state => state.metric,
    metric_defs: state => state.metric_defs,
    metrics: state => state.metrics,
    metric_option: state => state.metric_option,
    metric_options: state => state.metric_options,
    org: state => state.org,
    orgs: state => state.orgs,
    program: state => state.program,
    program_asset: state => state.program_asset,
    program_assets: state => state.program_assets,
    program_progress: state => state.program_progress,
    programs: state => state.programs,
    program_metric: state => state.program_metric,
    program_metrics: state => state.program_metrics,
    program_text: state => state.program_text,
    program_texts: state => state.program_texts,
    program_geo_metric: state => state.program_geo_metric,
    program_geo_metrics: state => state.program_geo_metrics,
    total_count: state => state.total_count,
    user: state => state.user,
    user_account: state => state.user_account,
    users: state => state.users,
  },
  actions: {
    async api({commit, dispatch}, callOptions){
      // let {method, url, parms, body, selector, mutator} = callOptions
      try{
        commit('clearMessageAndErrors');
        commit('incrementInProcess');
        let data = await callApi(this.state.jwt, callOptions);
        if(data && data.message) commit('setMessage', data.message);
        if(callOptions.mutator){
          if(callOptions.selector){
            commit(mutator, data[selector]);
          } else {
            commit(mutator, data);
          }
          
        }
        // Regardless of mutator, selector options, the raw data is returned.
        return data;
      }catch(ex){
        // console.error('error handling in store: %o \n  %s', ex.name, ex.message);
        if(ex instanceof ApiNotAvailableError){
          commit('setGlobalError', ex.message);
        } else if(ex instanceof ApiError){
          if(ex instanceof ApiAuthorizationError){ 
            commit('incrementAuthErrorCount');
          }
          console.error(ex);
          console.error(ex.data);
          if(ex.data){
            if(ex.data.message) commit('setError', ex.data.message);
            if(ex.data.error) console.error(ex.data.error);
            if(ex.data.errors) commit('setErrors', ex.data.errors);
          }
        } else {
          commit('setError', "Error. "+ex.message);
          console.error(ex);
        }
      } finally {
        commit('decrementInProcess')
      }
    },
    async apiGet({dispatch}, callOptions) {
      callOptions.method = 'GET';
      return await dispatch('api',callOptions);
    },
    async apiPost({dispatch}, callOptions) {
      callOptions.method = 'POST';
      return await dispatch('api',callOptions);
    },
    async apiPut({dispatch}, callOptions) {
      callOptions.method = 'PUT';
      return await dispatch('api',callOptions);
    },
    async apiDelete({dispatch}, callOptions) {
      callOptions.method = 'DELETE';
      return await dispatch('api',callOptions);
    },

    flashMessage({commit}, message){
      commit('setMessage', message);
      setTimeout(()=>{
        commit('setMessage', null);
        commit('setDirty', false);
      }, 3000)
    },

    /**
     * @deprecated use apiGet with mutator and selector options instead.
     * All-purpose load (get by id) action.
     * @param {object} payload
     * @param {string} payload.entity name of entity (camelcase)
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/org/48239/user/8723').
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
    async loadEntity({dispatch, commit}, payload) {
      let eTitleCase = payload.entity.charAt(0).toUpperCase()+payload.entity.substr(1);
      let response = await dispatch('apiGet', {url: `/api/v1${payload.relative_path}`});
      if(!this.getters.has_error){
        commit(`set${eTitleCase}`, response);
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
    },
    /**
     * @deprecated use apiGet with mutator and selector options instead.
     * All-purpose search action (note: issues POST request).
     * @param {object} payload
     * @param {string} payload.setter vuexSetter to commit the items to
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/org/48239/user').
     * @param {object} payload.search search parameters
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
     async searchEntities({dispatch, commit}, payload) {
      let response = await dispatch('apiGet', {url: `/api/v1${payload.relative_path}`, parms: payload.search});
      if(!this.getters.has_error){
        commit(payload.setter, response.items);
        if(response.total){
          commit(`setTotalCount`, response.total);
        }
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
      
    },
    /**
     * @deprecated use apiGet with mutator and selector options instead.
     * All-purpose search action (note: issues POST request).
     * @param {object} payload
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/org/48239/user').
     * @param {string} payload.setter store setter for the results 
     * @param {object} payload.search search parameters
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
    async searchEntitiesAdvanced({dispatch, commit}, payload) {
      let response = await dispatch('apiPost', {url: `/api/v1${payload.relative_path}`, body: payload.search});
      if(!this.getters.has_error){
        commit(payload.setter, response.items);
        if(response.total){
          commit(`setTotalCount`, response.total);
        }
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
      
    },

    /**
     * @deprecated use apiPut/apiPost with mutator and selector options instead.
     * All-purpose save entity action.
     * @param {object} payload
     * @param {string} payload.setter name of entity setter
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/org/48239/user'). Omit an ending id as this method will add it automatically if needed.
     * @param {number} payload.id entity id (if applicable)
     * @param {object} payload.data the entity data being submitted
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
    async saveEntity({dispatch, commit}, payload) {
      let response = null;
      if(payload.id || payload.data.id){
        response = await dispatch('apiPut', {url: `/api/v1${payload.relative_path}/${payload.id || payload.data.id}`, body: payload.data});
      } else {
        response = await dispatch('apiPost', {url: `/api/v1${payload.relative_path}`, body: payload.data});
      }
      if(!this.getters.has_error){
        if(payload.setter){
          commit(payload.setter, response);
        }
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
    },

    /**
     * All-purpose save many entity action (issues POST).
     * @param {object} payload
     * @param {string} payload.setter name of entity (camelcase)
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/org/48239/user'). 
     * @param {object} payload.data the entity data being submitted (usually an array)
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
     async saveMany({dispatch, commit}, payload) {
      let response = null;

      response = await dispatch('apiPost', {url: `/api/v1${payload.relative_path}`, body: payload.data});

      if(!this.getters.has_error){
        commit(`${payload.setter}`, response);
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
    },

    /**
     * @deprecated use apiDelete with mutator and selector options instead.
     * All-purpose delete by id action.
     * @param {object} payload
     * @param {string} payload.entity name of camelcase (lowercase)
     * @param {string} payload.relative_path path to the resource (include leading slash e.g. '/orgs/48239')
     * @param {number} payload.id entity id (if applicable)
     * @param {object} payload.flash a confirmation message flashed at end of operation (optional)
     */
    async deleteEntity({dispatch, commit}, payload) {
      let eTitleCase = payload.entity.charAt(0).toUpperCase()+payload.entity.substr(1);
      await dispatch('apiDelete', {url: `/api/v1${payload.relative_path}`});
      if(!this.getters.has_error){
        commit(`set${eTitleCase}`, null);
        if(payload.flash){
          dispatch('flashMessage', payload.flash);
        }
      }
    },

    //
    // API actions
    //

    //
    // Public search for programs and view result details
    // 
    async searchForPrograms({dispatch,commit}, payload){ 
      let response = await dispatch('apiPost', {url: `/search`, body: payload.search});
      if(!this.getters.has_error){
        commit('setPrograms', response.items);
        if(response.total){
          commit(`setTotalCount`, response.total);
        }
      }
    },
   
    async getProgramDetails({dispatch,commit}, payload){ 
      let response = await dispatch('apiGet', {url: `/search/p/${payload.program_id}`});
      if(!this.getters.has_error){
        commit('setProgram', response.program);
        commit('setProgramMetrics', response.program_metrics);
        commit('setProgramGeoMetrics', response.program_geo_metrics);
        commit('setProgramAssets', response.program_assets);
        
      }
    },

    async getProgramProgress({dispatch,commit}, payload){ 
      let response = await dispatch('apiGet', {url: `/search/p/${payload.program_id}/progress`});
      if(!this.getters.has_error){
        commit('setProgramProgress', response);
      }
    },

    //
    // Aliases
    // 
    async loadAliases({dispatch}){ 
      return await dispatch('loadEntity', { entity:'Aliases', relative_path:`/qbrowser/aliases` }); 
    },

   
    //
    // Category Definitions
    // 
    async loadCategoryDefs({dispatch}){ 
      return await dispatch('loadEntity', { entity:'CategoryDefs', relative_path:`/category/all` }); 
    },

    //
    // Constants
    // 
    async loadConstants({dispatch}){ 
      return await dispatch('loadEntity', { entity:'constants', relative_path:`/constants` }); 
    },

    //
    // Geo
    //
    async loadGeo({dispatch}, id){ 
      return await dispatch('loadEntity', { entity:'Geo', relative_path:`/geo/${id}` }); 
    },
    async searchGeos({dispatch}, parms){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setGeos', relative_path:`/geo/search`, search: parms}); 
    },
    async deleteGeo({dispatch}, id){ 
      return await dispatch('deleteEntity', { entity:'Geo', relative_path:`/geo/${id}`, flash: 'Removed geographic locale.'}); 
    },
    async saveGeo({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setGeo', relative_path:`/geo`, id: p.id, data: p, flash: 'Saved geographic locale.'}); 
    },

    //
    // Geography (tree)
    //
    async loadGeography({dispatch}){ 
      return await dispatch('loadEntity', { entity:'Geography', relative_path:`/geo/geography` }); 
    },

    //
    // Geographic Metric
    //
    async loadGeoMetric({dispatch}, id){ 
      return await dispatch('loadEntity', { entity:'GeoMetric', relative_path:`/metric/geo/${id}` }); 
    },
    async searchGeoMetrics({dispatch}, parms){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setGeoMetrics', relative_path:`/metric/geo/search`, search: parms}); 
    },
    async deleteGeoMetric({dispatch}, id){ 
      return await dispatch('deleteEntity', { entity:'GeoMetric', relative_path:`/metric/geo/${id}`, flash: 'Removed geographic metric.'}); 
    },
    async saveGeoMetric({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setGeoMetric', relative_path:`/metric/geo`, id: p.id, data: p, flash: 'Saved geographic metric.'}); 
    },

    //
    // Metric
    //
    async loadMetric({dispatch}, id){ 
      return await dispatch('loadEntity', { entity:'Metric', relative_path:`/metric/${id}` }); 
    },
    async searchMetrics({dispatch}, parms){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setMetrics', relative_path:`/metric/search`, search: parms}); 
    },
    async deleteMetric({dispatch}, id){ 
      return await dispatch('deleteEntity', { entity:'Metric', relative_path:`/metric/${id}`, flash: 'Removed metric.'}); 
    },
    async saveMetric({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setMetric', relative_path:`/metric`, id: p.id, data: p, flash: 'Saved metric.'}); 
    },

    //
    // Metric Defs
    //
    async loadMetricDefs({dispatch}){
      return await dispatch('loadEntity', { entity:'MetricDefs', relative_path:`/metric/all` }); 
    },

    //
    // Org
    //
    async loadOrg({dispatch}, id){ 
      return await dispatch('loadEntity', { entity:'Org', relative_path:`/org/${id}` }); 
    },
    async searchOrgs({dispatch}, parms){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setOrgs', relative_path:`/org/search`, search: parms}); 
    },
    async deleteOrg({dispatch}, id){ 
      return await dispatch('deleteEntity', { entity:'Org', relative_path:`/org/${id}`, flash: 'Removed organization.'}); 
    },
    async saveOrg({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setOrg', relative_path:`/org`, id: p.id, data: p, flash: 'Saved organization.'}); 
    },

    //
    // Program
    //
    async loadProgram({dispatch}, p){ 
      return await dispatch('loadEntity', { entity:'Program', relative_path: `/org/${p.org_id}/program/${p.id}` }); 
    },
    async searchPrograms({dispatch}, p){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setPrograms', relative_path: `/org/${p.org_id}/program/search`, search: p}); 
    },
    async deleteProgram({dispatch}, p){ 
      return await dispatch('deleteEntity', { entity:'Program', relative_path: `/org/${p.org_id}/program/${p.id}`, flash: 'Removed program.' }); 
    },
    async saveProgram({dispatch}, p){ 
      return await dispatch('saveEntity', { relative_path: `/org/${p.org_id}/program`, id: p.id, data: p, flash: 'Saved program info.'}); 
    },

    //
    // Program Asset
    //
    async loadProgramAsset({dispatch}, p){ 
      return await dispatch('loadEntity', { entity:'ProgramAsset', relative_path: `/org/${p.org_id}/program/${p.program_id}/asset/${p.id}` }); 
    },
    async loadProgramAssets({dispatch}, p){ 
      return await dispatch('searchEntities', { setter:'setProgramAssets', relative_path: `/org/${p.org_id}/program/${p.program_id}/asset`, search: p}); 
    },
    async deleteProgramAsset({dispatch}, p){ 
      return await dispatch('deleteEntity', { entity:'ProgramAsset', relative_path: `/org/${p.org_id}/program/${p.program_id}/asset/${p.id}`, flash: 'Removed asset.' }); 
    },
    async saveProgramAsset({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setProgramAsset', relative_path: `/org/${p.org_id}/program/${p.program_id}/asset`, id: p.id, data: p, flash: 'Saved asset.'}); 
    },

    //
    // Program Metric
    //
    async loadProgramMetric({dispatch}, p){ 
      return await dispatch('loadEntity', { entity:'ProgramMetric', relative_path: `/org/${p.org_id}/program/${p.program_id}/pm/${p.id}` }); 
    },
    async loadProgramMetrics({dispatch}, p){ 
      return await dispatch('searchEntities', { setter:'setProgramMetrics', relative_path: `/org/${p.org_id}/program/${p.program_id}/pm`, search: p}); 
    },
    async deleteProgramMetric({dispatch}, p){ 
      return await dispatch('deleteEntity', { entity:'ProgramMetric', relative_path: `/org/${p.org_id}/program/${p.program_id}/pm/${p.id}`, flash: 'Removed info.' }); 
    },
    async saveProgramMetric({dispatch}, p){ 
      return await dispatch('saveEntity', { setter:'setProgramMetric', relative_path: `/org/${p.org_id}/program/${p.program_id}/pm`, id: p.id, data: p, flash: 'Saved info.'}); 
    },

    //
    // Program Text
    //
    async getProgramTextForAlias({dispatch}, {program_id, alias}){
      //public
      return await dispatch('apiGet', {url: `/search/p/${program_id}/txtalias/${alias}` }); 
    },
    async saveProgramText({dispatch}, p){ 
      let res = await dispatch('saveEntity', { setter:'setProgramText', relative_path: `/org/${p.org_id}/program/${p.program_id}/txt`, id: p.id, data: p, flash: 'Saved info.'});
      if(!this.getters.has_error){
        let x = this.state.program_texts.findIndex(t=>t.alias===p.alias);
        if(x>=0) this.state.program_texts.splice(idx,1,p);
      }
      return res;
    },

    //
    // Program Geo Metric
    //
    async loadProgramGeoMetric({dispatch}, p){ 
      return await dispatch('loadEntity', { entity:'ProgramGeoMetric', relative_path: `/org/${p.org_id}/program/${p.program_id}/pgm/${p.id}` }); 
    },
    async loadProgramGeoMetrics({dispatch}, p){ 
      return await dispatch('searchEntities', { setter:'setProgramGeoMetrics', relative_path: `/org/${p.org_id}/program/${p.program_id}/pgm`, search: p}); 
    },
    async removeProgramGeoMetrics({dispatch, commit}, {org_id, program_id, geo_metric_id }){ 
      await dispatch('apiDelete', {url: `/api/v1/org/${org_id}/program/${program_id}/pgm/remove`, parms: {geo_metric_id}});
      if(!this.getters.has_error){
        commit('setProgramGeoMetrics', [] );
        dispatch('flashMessage', 'Removed info.');
      }
    },
    async replaceProgramGeoMetrics({dispatch}, p){ 
      return await dispatch('saveMany', { setter:'setProgramGeoMetrics', relative_path: `/org/${p.org_id}/program/${p.program_id}/pgm/replace`,  data: p.values, flash: 'Saved info.'}); 
    },

    //
    // Region
    //
    async loadRegion({dispatch}, p){ 
      return await dispatch('loadEntity', { entity:'Region', relative_path: `/org/${p.org_id}/program/${p.id}` }); 
    },

    //
    // User Search
    //
    async searchUsers({dispatch}, p){ 
      return await dispatch('searchEntitiesAdvanced', { setter:'setUsers', relative_path: `/org/${p.org_id}/user/search`, search: p}); 
    },

    //
    // UserAccount editing (different than "User")
    //
    async loadUserAccount({commit, dispatch}, payload){
      let response = await dispatch('apiGet', {url: `/api/v1/org/${payload.org_id}/user/${payload.id}`}); 
      commit(`setUserAccount`, response);
    },

    async deleteUserAccount({commit, dispatch}, payload){
      await dispatch('apiDelete', {url: `/api/v1/org/${payload.org_id}/user/${payload.id}`});
      if(!this.getters.has_error){
        commit(`setUserAccount`, null);
        dispatch('flashMessage', `User removed.`);
      }
    },
    
    async saveUserAccount({commit, dispatch}, payload){ 
      let response = null;
      if(payload.id){
        response = await dispatch('apiPut', {url: `/api/v1/org/${payload.org_id}/user/${payload.id}`, body: payload});
      } else {
        response = await dispatch('apiPost', {url: `/api/v1/org/${payload.org_id}/user/`, body: payload});
      }
      if(!this.getters.has_error){
        commit(`setUserAccount`, response);
        dispatch('flashMessage', 'Saved user.');
      }
      
    },

    // save a password reset.
    async savePassword({dispatch}, payload){
      await dispatch('apiPost', {url: `/reset-password`, body: {token: payload.token, password: payload.password} });
    },

  },
  modules: {
  }
})

