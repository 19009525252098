<template lang="pug">
b-container.border.border-primary(style="border-radius: 10px;").mt-2.p-2
  .row
    .col.text-center.pb-2(v-if="index>0")
      b-form-checkbox( v-model="my.logical" unchecked-value="OR" value="AND" variant="secondary" button size="sm" @input="observeChanges") {{my.logical}}
      
  .row(v-for="(expr, idx) in my.conditions" :key="idx")
    QueryCondition( :aliases="aliases" :index="idx" :expr="expr" :alias="expr.alias" :operator="expr.operator" :value="expr.value"
      @changed="observeConditionChanges" @removed="removeCondition" )

  .col.text-right
      b-button(@click="addConditionExpression" size="sm" title="add condition" :disabled="busy")
        b-icon-plus(size="sm") 
        span Add Condition
</template>

<script>
import {mapGetters} from 'vuex'
import QueryCondition from '@/components/QueryCondition.vue'
const EVT_CHANGED="changed";
const EVT_REMOVED="removed";
export default {
  name: 'QueryGroup',
  components: { QueryCondition },
  props: {
    aliases: {type: Array},

    //initial state...
    index: {type: Number, default: false, required: true},
    logical: {required: false, default: 'AND'},
    conditions: {type: Array, required: false},
  },

  computed: {
    ...mapGetters(['busy',]),
  },

  data(){
    return {
      error: null,
      my: {
        index: 0,
        logical: 'AND',
        conditions: [],//QueryConditions
      }
    };
  },

  created(){
    this.my.index = this.index;
    this.my.logical = this.logical|| 'AND';
    this.my.conditions = this.conditions || [];
  },

  methods:{
    observeChanges(){
      if(this.is_valid) this.$emit(EVT_CHANGED, this.my);
    },

    observeConditionChanges(condition){
      console.log(`changed: ${condition.alias}`)
      // replace expression at index with payload
      this.my.conditions.splice(condition.index, 1, condition);

      //passes a changed event up to the parent.
      this.$emit(EVT_CHANGED, this.my);
    },
    addConditionExpression(){
      let initial = {
        index: this.my.conditions.length,
        logical: 'AND', 
        alias: '', 
        operator:'=', 
        value: null
      };
      //console.log(' --> %o', initial);
      this.my.conditions.push(initial);
    },
    removeCondition(index){
      console.log(`Removing condition with index ${index} inside the list of ${this.my.conditions.length}`)
      let actualArrayIndex = this.my.conditions.findIndex(c=>c.index==index);

      this.my.conditions.splice(actualArrayIndex, 1);

      if(this.my.conditions.length === 0){ 
        console.log(`No conditions remaining. Removing group...`)
        this.$emit(EVT_REMOVED, this.my.index);
      } else{ 
        this.$emit(EVT_CHANGED, this.my);
      }
    },
    
  }
}
</script>
