<template lang="pug">
Base(:title="'\uD83C\uDF93 ' + (program ? program.name : 'Program')" :with_nav="true")
  template(#title)
    b-card-text.d-flex.justify-content-between
      div
        b-button(pill size="sm" variant="light" @click="$router.go(-1)" )
          b-icon-arrow-left-short
        span.ml-2 {{'\uD83C\uDF93 ' + (program ? program.name : 'Program')}}
      //- div
      //-   small(v-if="program_progress && !busy" ).text-info Profile {{Number(program_progress.count/program_progress.available).toLocaleString(undefined, {style:'percent'})}} Complete
          
  b-tabs( pills vertical small v-if="program_reloaded && program")
    b-tab(href="#" title="Overview" )
      b-container.p-0(style="max-height: 200px; overflow: hidden;")
        b-img.m-0(v-if="program_banner_url" :src="program_banner_url" fluid-grow )
      .row
        .col
          EntityDropdownField( readonly :entity="program" property_name="program_type" label="Program Type:" :options="program_type_options")
          EntityTextField( readonly :entity="program" property_name="chairman" label="Chairperson:")
          EntityTextField( readonly :entity="program" property_name="program_director" label="Program Director:")
          EntityTextField( readonly :entity="program" property_name="associate_program_director" label="Associate Program Director:")
          EntityTextField( readonly :entity="program" property_name="program_coordinator" label="Program Coordinator:")
          ProgramMetricWidget( readonly 
            :program_id="program.id" 
            :metric="met('fellows_subspecialty')"
            :values="metv('fellows_subspecialty')"
            :category_options="catopts('subspecialty')" 
            label="Number of Fellows:"
            empty_value="No Fellowship")

        .col
          EntityNumberField( readonly :entity="program" property_name="residents_total" label="Total Residents in Program:" :min="0" :max="100" :step="1")
          EntityDropdownField( readonly :entity="program" property_name="length_of_training" label="Length of Training:" :options="length_of_training_options")
          EntityTextField( readonly :entity="program" property_name="main_institution" label="Main Institution:")
          EntityNumberField( readonly :entity="program" property_name="weeks_dedicated_research_time" label="Dedicated Research Time:" :min="0" :max="26" :step="1" unit="weeks")
          EntityNumberField( readonly :entity="program" property_name="trauma_level" label="Trauma Level:" :min="0" :max="6" :step="1" unit="level" prepend)
      
        .col
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="hospital_affiliations" label="Hospital Affiliations:" :rows="2" :max_rows="5" :max_chars="1000")
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="external_rotation" label="External Rotation:" :rows="2" :max_rows="5" :max_chars="500")
          
      .row
        .col
          EntityNumberField( readonly :entity="program" property_name="five_year_abos_part1_pass_rate" label="5 year Orthopaedic Surgery Board Pass Rate:" :min="0" :max="100" :step="1" unit="%")
          ProgramMetricWidget(  
            :program_id="program.id" 
            :metric="met('resident_subspecialty')"
            :values="metv('resident_subspecialty')"
            :category_options="catopts('subspecialty')" 
            label="Fellowship Subspecialty Selection (past 5 years):"
            empty_value="General")
          
        .col
          EntityNumberField( readonly :entity="program" property_name="attendings_total" label="Number of Attendings:" :min="0" :max="100" :step="1" )
          EntityNumberField( readonly :entity="program" property_name="attendings_minority" label="Number of URiM Attendings:" :min="0" :max="100" :step="1" :denominator="program.attendings_total" )
          EntityNumberField( readonly :entity="program" property_name="attendings_female" label="Number of Female Attendings:" :min="0" :max="100" :step="1" :denominator="program.attendings_total")
          EntityBooleanField( readonly :entity="program" property_name="diversity_initiatives" label="Participates in Diversity Initiatives" :tf_labels="['yes','no']" )
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="diversity_initiative_info" label="Diversity Initiative Information:" :rows="2" :max_rows="5" :max_chars="500" v-if="program.diversity_initiatives")
      
        .col
          ProgramMetricWidget( readonly 
            :program_id="program.id" 
            :metric="met('highlights')"
            :values="metv('highlights')"
            label="Program Highlights (List 5):")

      .row
        .col  
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="mission_statement" label="Mission Statement:" :rows="2" :max_rows="5" :max_chars="2000" )
          
        .col
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="didactics_structure" label="Structure of Didactics:" :rows="2" :max_rows="5" :max_chars="500")
          
    b-tab(href="#" title="Current Residents" )
      .row
        .col
          //- EntityNumberField( readonly :entity="program" property_name="step1_median" label="Median USMLE Step 1:" :min="192" :max="300" )
          EntityNumberField( readonly :entity="program" property_name="step2_median" label="Median USMLE Step 2:" :min="200" :max="300" )

          EntityNumberField( readonly :entity="program" property_name="comlex_usa_level1_median" label="Median COMLEX-USA Level 1:" :min="9" :max="999" )
          EntityNumberField( readonly :entity="program" property_name="comlex_usa_level2_median" label="Median COMLEX-USA Level 2:" :min="9" :max="999" )

          EntityNumberField( readonly :entity="program" property_name="residents_minority" label="Number of URiM Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( readonly :entity="program" property_name="residents_female" label="Number of Female Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( readonly :entity="program" property_name="residents_associated_school" label="Residents from Associated Medical School:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( readonly :entity="program" property_name="residents_elective_rotation" label="Residents that completed an Elective Rotation:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )

          EntityNumberField( readonly :entity="program" property_name="residents_aoa_status" label="Number of Residents Achieved AOA Status:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          EntityNumberField( readonly :entity="program" property_name="residents_d_o" label="Number of D.O. Residents:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total")
          EntityNumberField( readonly :entity="program" property_name="residents_intl_graduated" label="Number of Residents Internationally Graduated:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total"  )
          EntityNumberField( readonly :entity="program" property_name="residents_unmatched_initially" label="Number of Residents Unsuccessful Match into Orthopaedics on Initial Attempt:" :min="0" :max="program.residents_total || 999" :denominator="program.residents_total" )
          
        .col
        
          ProgramGeographicMetricWidget( readonly 
            label="Percentage of Residents by Medical School Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('resident_med_school_region_pct')"
            :values="gmetv('resident_med_school_region_pct')"
            catch_all_label="International" )
          
          ProgramGeographicMetricWidget( readonly 
            label="Percentage of Residents by Hometown Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('resident_hometown_region_pct')"
            :values="gmetv('resident_hometown_region_pct')"
            catch_all_label="International" )
        
    b-tab(href="#" title="Program Interview" )
      .row
        .col
          EntityNumberField( readonly :entity="program" property_name="avg_yearly_applications" label="Average Number of Applications per Year:" :min="0" :max="2500" )
          EntityNumberField( readonly :entity="program" property_name="total_yearly_interviews" label="Average Number of Interviews:" :min="0" :max="999" unit="per year" )
          EntityNumberField( readonly :entity="program" property_name="yearly_non_rotator_interviews" label="Non-Rotator Interviews Granted (previous year):" :min="0" :max="999" )
          EntityNumberField( readonly :entity="program" property_name="yearly_rotator_interviews" label="Rotator Interviews Granted (previous year):" :min="0" :max="999" )
          EntityNumberField( readonly :entity="program" property_name="avg_away_rotators" label="Average Number of Away Rotators:" :min="0" :max="999" )
        
        .col
          EntityTextField( readonly :entity="program" property_name="months_available_away_rotations" label="Months Available for Away Rotation:" )
          EntityNumberField( readonly :entity="program" property_name="avg_monthly_rotators" label="Average Number of Away Rotators Per Month:" :min="0" :max="999" )
          EntityBooleanField( readonly :entity="program" property_name="rotators_interviewed_during_away_rotation" label="Rotators Interviewed During the Away Rotation:" :tf_labels="['yes','no']" )
          

    b-tab(href="#" title="Application Process" )
      b-row
        .col
          EntityBooleanField( readonly :entity="program" property_name="use_privatized_scoring_system" label="Use of Privatized Scoring System:" :tf_labels="['yes','no']" )
          EntityBooleanField( readonly :entity="program" property_name="use_interview_screening_tools" label="Use of Interview Screening Tools:" :tf_labels="['yes','no']" )

          //- EntityTextField( readonly :entity="program" property_name="step1_cutoff_score_rotators" label="USMLE Step 1 Cutoff Score - Rotators:" )
          //- EntityTextField( readonly :entity="program" property_name="step1_cutoff_score_non_rotators" label="USMLE Step 1 Cutoff Score - Non-Rotators:" )
          EntityTextField( readonly :entity="program" property_name="step2_cutoff_score_rotators" label="USMLE Step 2 Cutoff Score - Rotators:" )
          EntityTextField( readonly :entity="program" property_name="step2_cutoff_score_non_rotators" label="USMLE Step 2 Cutoff Score - Non-Rotators:" )
          
          EntityTextField( readonly :entity="program" property_name="comlex_usa_level1_cutoff_rotators" label="COMLEX-USA Level 1 Cutoff Score - Rotators:" )
          EntityTextField( readonly :entity="program" property_name="comlex_usa_level1_cutoff_non_rotators" label="COMLEX-USA Level 1 Cutoff Score - Non-Rotators:" )
          EntityTextField( readonly :entity="program" property_name="comlex_usa_level2_cutoff_rotators" label="COMLEX-USA Level 2 Cutoff Score - Rotators:" )
          EntityTextField( readonly :entity="program" property_name="comlex_usa_level2_cutoff_non_rotators" label="COMLEX-USA Level 2 Cutoff Score - Non-Rotators:" )

        .col
          EntityTextField( readonly :entity="program" property_name="clerkship_grade_criteria_year4" label="4th Year Clerkship Grades Criteria:" )
          EntityTextField( readonly :entity="program" property_name="clerkship_grade_criteria_year3" label="3rd Year Clerkship Grades Criteria:" )
          EntityBooleanField( readonly :entity="program" property_name="aoa_status_required" label="AOA Status Required:" :tf_labels="['yes','no']" )
          EntityTextField( readonly :entity="program" property_name="graduates_considered_d_o" label="D.O. Graduates Considered:" hint="Please elaborate." )
          EntityTextField( readonly :entity="program" property_name="graduates_considered_intl" label="International Graduates Considered:" hint="Please elaborate." )
          EntityBooleanField( readonly :entity="program" property_name="department_chair_lor_required" label="Department Chair Letter of Recommendation Required:" :tf_labels="['yes','no']" )
          
      b-row
        b-col
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="looking_for_verbiage" label="What are you looking for in an applicant? (100 Words):" :rows="2" :max_rows="10" :max_chars="2500")
         
    b-tab(href="#" title="Rank List Data" )
      b-row
        .col
          //- EntityTextField( readonly :entity="program" property_name="rlist_median_step1_narrative" label="Median USMLE Step 1 Score:" hint="Example: 247 (Range 225-268)")
          EntityTextField( readonly :entity="program" property_name="rlist_median_step2_narrative" label="Median USMLE Step 2 Score:" hint="Example: 254 (Range 227-272)" )
          EntityTextField( readonly :entity="program" property_name="rlist_median_comlex_usa_level1_narrative" label="Median COMLEX-USA Level 1 Score:" hint="Example: 801 (Range 770-820)")
          EntityTextField( readonly :entity="program" property_name="rlist_median_comlex_usa_level2_narrative" label="Median COMLEX-USA Level 2 Score:" hint="Example: 822 (Range 785-840)" )
          EntityNumberField( readonly :entity="program" property_name="rlist_minority" label="URiM Ranked Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( readonly :entity="program" property_name="rlist_female" label="Female Ranked Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( readonly :entity="program" property_name="rlist_aoa_status" label="Ranked Applicants with AOA Status:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( readonly :entity="program" property_name="rlist_top_10pct_med_school" label="Ranked Applicants within top 10% of Medical School Class:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( readonly :entity="program" property_name="rlist_d_o" label="Ranked D.O. Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
          EntityNumberField( readonly :entity="program" property_name="rlist_international" label="Ranked International Applicants:" :min="0" :max="25" :denominator="25" hint="out of top 25")
        
        .col
          ProgramGeographicMetricWidget( readonly 
            label="Percentage of Ranked Applicants by Medical School Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('rlist_med_school_region_pct')"
            :values="gmetv('rlist_med_school_region_pct')"
            catch_all_label="International" )

          ProgramGeographicMetricWidget( readonly 
            label="Percentage of Ranked Applicants by Hometown Region"
            short_label="region"
            :program="program"
            :geo_metric="gmet('rlist_hometown_region_pct')"
            :values="gmetv('rlist_hometown_region_pct')"
            catch_all_label="International" )


    b-tab(href="#" title="Contact Info" )
      .row
        .col
          EntityAddressInput( readonly :entity="program" label="Address" )
          EntityTextField( readonly :entity="program" property_name="phone" label="Phone")
          EntityTextField( readonly :entity="program" property_name="fax" label="Fax")
      
        .col
          EntityTextField( readonly :entity="program" property_name="residents_website" label="Current Residents website:" display_type="url")
          EntityTextField( readonly :entity="program" property_name="away_rotation_website" label="Away rotation information website:" display_type="url")
          EntityTextField( readonly :entity="program" property_name="facebook_url" label="Facebook" display_type="url")
          EntityTextField( readonly :entity="program" property_name="instagram_url" label="Instagram" display_type="url")
          EntityTextField( readonly :entity="program" property_name="linkedin_url" label="LinkedIn" display_type="url")
          EntityTextField( readonly :entity="program" property_name="twitter_url" label="Twitter" display_type="url")

    b-tab(href="#")
      template(#title)
        .text-left.callout Current Season
      .row
        .col
          EntityDropdownField( readonly :entity="program" property_name="virtual_interviews" label="Virtual interviews available?"  :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'TBD'}]")
          EntityTextField( readonly :entity="program" property_name="virtual_interview_dates" label="Virtual interview dates:" v-show="program.virtual_interviews==='yes'")
          EntityDropdownField( readonly :entity="program" property_name="in_person_interviews" label="In-person interviews available?"  :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'TBD'}]")
          EntityTextField( readonly :entity="program" property_name="in_person_interview_dates" label="In-person interview dates:" v-show="program.in_person_interviews==='yes'")
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="additional_interview_info" label="Additional interview information:" :rows="2" :max_rows="5" :max_chars="1000" )
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="virtual_opportunities" label="Virtual opportunities:" :rows="2" :max_rows="5" :max_chars="1000")


        .col
          EntityDropdownField( readonly :entity="program" property_name="open_house" label="Open house session available?" :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" :label_cols="8")
          EntityDropdownField( readonly :entity="program" property_name="in_person_open_house" label="In-person open house?" :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" v-show="program.open_house" :label_cols="8")
          EntityTextField( readonly :entity="program" property_name="in_person_open_house_dates" label="In-person open house dates:" v-show="program.open_house && program.in_person_open_house" )
          EntityDropdownField( readonly :entity="program" property_name="virtual_open_house" label="Virtual open house?"  :options="[{text:'yes',value:'yes'},{text:'no',value:'no'},{text:'TBD',value:'tbd'}]" v-show="program.open_house" :label_cols="8")
          EntityTextField( readonly :entity="program" property_name="virtual_open_house_dates" label="Virtual open house dates:" v-show="program.open_house && program.virtual_open_house")
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="additional_open_house_info" label="Additional open house information:" :rows="2" :max_rows="5" :max_chars="1000"  v-show="program.open_house" )
          
          EntityBooleanField( readonly :entity="program" property_name="aamc_eras_supp_application" label="Participating in AAMC ERAS Preference Signaling?" :tf_labels="['yes','no']" :label_cols="8")
            template(#more_info)
              div.small
                b-link(href="https://students-residents.aamc.org/applying-residencies-eras/supplementalerasapplication" target="_blanks" style="text-decoration: underline;")
                  span More information about AAMC ERAS
                  b-icon-box-arrow-in-up-right.ml-1
                  
          EntityBooleanField( readonly :entity="program" property_name="offer_day_aoa_cord_uod" label="Participating in AOA/CORD Universal Interview Offer Day?" :tf_labels="['yes','no']" :label_cols="8")
            //- template(#more_info)
            //-   div.small
            //-     b-link(href="https://www.aoassn.org/universal-interview-offer-day-2021-2022/" target="_blanks" style="text-decoration: underline;")
            //-       span More information about UOD
            //-       b-icon-box-arrow-in-up-right.ml-1
          EntityTextField( readonly :entity="program" property_name="offer_day_interview_date" label="If not participating in AOA/CORD Universal Offer Day, what day(s) will you offer interviews?" v-show="!program.offer_day_aoa_cord_uod")
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="additional_offer_day_info" label="Additional offer day information:" :rows="2" :max_rows="5" :max_chars="1000")
    
    b-tab(href="#")
      template(#title)
        .text-left Research Opportunities

      .row
        .col
          EntityBooleanField( readonly :entity="program" property_name="gap_year_research" label="Gap year option" :tf_labels="['yes','no']" )
          ProgramTextWidget( readonly  :org_id="program.org_id" :program_id="program.id" alias="gap_year_research_info" label="Gap year information:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.gap_year_research")
        .col
          EntityBooleanField( readonly :entity="program" property_name="postgrad_research" label="Post-graduation option" :tf_labels="['yes','no']" )
          ProgramTextWidget( readonly  :org_id="program.org_id" :program_id="program.id" alias="postgrad_research_info" label="Post-graduation information:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.postgrad_research")
        .col
          EntityBooleanField( readonly :entity="program" property_name="salaried" label="Is salary provided?" :tf_labels="['yes','no']" )
      .row
        .col
          ProgramTextWidget( readonly :org_id="program.org_id" :program_id="program.id" alias="research_contact_info" label="Contact information for research opportunity:" :rows="2" :max_rows="5" :max_chars="1000" v-if="program.gap_year_research || program.postgrad_research")
  
</template>

<script>
import Base from '@/components/Base.vue'
import EntityBooleanField from '@/components/EntityBooleanField.vue'
import EntityDropdownField from '@/components/EntityDropdownField.vue'
import ProgramGeographicMetricWidget from '@/components/ProgramGeographicMetricWidget.vue'
import ProgramMetricWidget from '@/components/ProgramMetricWidget.vue'
import ProgramTextWidget from '@/components/ProgramTextWidget.vue'
import EntityNumberField from '@/components/EntityNumberField.vue'
import EntityAddressInput from '@/components/EntityAddressInput.vue'
import EntityTextField from '@/components/EntityTextField.vue'
import {mapGetters, mapActions} from 'vuex'
import baseMix from '@/mixins/base'
import ssvalidation from '@/mixins/ss-validation'
export default {
  name: 'ViewProgramDetails',

  mixins: [baseMix, ssvalidation],
  
  components: {
    Base,
    EntityAddressInput,
    EntityBooleanField,
    EntityDropdownField,
    EntityNumberField,
    EntityTextField,
    ProgramGeographicMetricWidget,
    ProgramMetricWidget,
    ProgramTextWidget,
  },
  
  computed: {
    ...mapGetters(['category_defs','geography','metric_defs','program','program_metrics','program_geo_metrics','program_progress',]),
    length_of_training_options(){
      if(!this.constants) return [];
      return this.constants.program.length_of_training.map(x => { return {text: x, value: x}; } );
    },

    program_type_options(){
      if(!this.constants) return [];
      return this.constants.program.program_types.map(x => { return {text: x, value: x}; } );
    },

    program_progress_pct(){
      if(this.program_progress){
        return Math.round( this.program_progress.count/this.program_progress.available * 100)
      } else {
        return 0;
      }
    },

    program_banner_url(){
      if(!this.program.banner) return "";
      return process.env.VUE_APP_ASSET_BASE_URL + this.program.banner;
    },
  },

  data(){
    return {
      program_reloaded: false,
    };
  },

  async created(){
    await this.load()
  },

  methods: {
    ...mapActions(['loadGeos','loadMetrics','loadMetrics','getProgramDetails','getProgramProgress']),
  
    catopts(alias){
      if(!alias || !this.category_defs) return [];
      let cat = this.category_defs.find(c=>c.alias===alias);
      return cat ? cat.options : [];
    },

    gmet(alias){
      if(!alias || !this.metric_defs) return null;
      return this.metric_defs.geo_metrics.find(gm=>gm.alias===alias);
    },

    gmetv(alias){
      let gmet = this.gmet(alias);
      if(this.program_geo_metrics && gmet) return this.program_geo_metrics.filter(pgm=>pgm.geo_metric_id==gmet.id);
      return null;
    },

    met(alias){
      if(!alias || !this.metric_defs) return null;
      return this.metric_defs.metrics.find(m=>m.alias===alias);
    },

    metv(alias){
      let met = this.met(alias);
      if(this.program_metrics && met) return this.program_metrics.filter(pm=>pm.metric_id==met.id);
      return null;
    },
    

    async load(){
      await this.getProgramDetails({program_id: this.$route.params.program_id});
      this.program_reloaded=true;
      await this.getProgramProgress({program_id: this.$route.params.program_id});
    },
    
  },
}
</script>

<style scoped>
.ilbl{
  font-size: .7em;
}
input, input[type=number], select, option, textarea {
  font-size: .7em;
}
.input-group-append, .input-group-prepend {
  font-size: .7em;
  height: 1.7rem;
}
.callout{
  font-weight: bolder;
  color: #794039;
}
</style>