<template lang="pug">
b-container.p-0.mt-1(fluid)
  div.border.rounded-lg.p-2(v-if="!editing" )
    div.text-primary.font9(v-b-hover="handleHover" :style="hovered ? 'cursor: pointer': ''" @click="toggleEdit" ) {{ label }}
    div.p-2.font9( )
      div {{ entity.address1 }}
      div {{ entity.address2 }}
      div {{ entity.address3 }}
      div {{ entity.city }}, {{ entity.state }} {{ entity.postal }} {{ entity.country }}
  div.bg-light(v-if="editing").p-2.bg-light
    div.font8(@click="toggleEdit" style="cursor: pointer;") {{ label }}
    
    b-form-group.m-1()
      b-form-input(size="sm" v-model="entity.address1" :class="validate('address1')" @update="updAddress1" placeholder="address")
      small.invalid-feedback {{validationMessage('address1')}}
    b-form-group.m-1()
      b-form-input(size="sm" v-model="entity.address2" :class="validate('address2')" @update="updAddress2" placeholder="(line 2)")
      small.invalid-feedback {{validationMessage('address2')}}
    b-form-group.m-1()
      b-form-input(size="sm" v-model="entity.address3" :class="validate('address3')" @update="updAddress3" placeholder="(line 3)")
      small.invalid-feedback {{validationMessage('address3')}}
    b-form-row.ml-1
      b-form-select(size="sm" v-model="entity.country" :class="validate('country')" :options="countries" @update="updCountry" placeholder="country")
        small.invalid-feedback {{validationMessage('country')}}
    b-form-row.ml-1
      b-form-group.m-0()
        b-form-input(size="sm" v-model="entity.city" :class="validate('city')" @update="updCity" placeholder="city")
        small.invalid-feedback {{validationMessage('city')}}
      b-form-group.m-0()
        b-form-select(v-if="entity.country=='USA'"      size="sm" v-model="entity.state" :class="validate('state')" @update="updState" :options="usa_states")
        b-form-select(v-else-if="entity.country=='CAN'" size="sm" v-model="entity.state" :class="validate('state')" @update="updState" :options="can_provinces")
        b-form-input(v-else                            size="sm" v-model="entity.state" :class="validate('state')" @update="updState" placeholder="region/province")
        small.invalid-feedback {{validationMessage('state')}}
      b-form-group.m-0()
        b-form-input(size="sm" v-model="entity.postal" :class="validate('postal')" @update="updPostal" placeholder="postal code")
        small.invalid-feedback {{validationMessage('postal')}}
     
        
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import {countries, usa_states, can_provinces} from '@/mixins/geo'
import ssvalidation from '@/mixins/ss-validation'
import which_role from '@/mixins/which-role'
export default {
  name: 'EntityAddressInput',
  
  mixins:[
    ssvalidation,
    which_role
  ],

  props:{
    entity: {required: true},
    hint: {type: String},
    label: {type: String, required: true},
    label_cols: { type: Number, default: 6 },

    is_editing: {type: Boolean, required: false, default: Boolean(false) },
  },

  data(){
    return {
      hovered: false,
      editing: this.is_editing || false,
      countries,
      usa_states,
      can_provinces,
      localValue: {
        address1: null,
        address2: null,
        address3: null,
        city: null,
        state: null,
        postal: null,
        country: null,
      },
    }
  },

  methods: {
    handleHover(hoverState){
      this.hovered = hoverState;
    },

    toggleEdit(){
      if(this.is_admin || this.is_director){
        this.editing = !this.editing;  
      } else {
        this.editing = false;
      }
      
    },

    updAddress1(value){this.localValue.address1 = value; this.$emit('change', this.localValue.address1); },
    updAddress2(value){this.localValue.address2 = value; this.$emit('change', this.localValue.address2); },
    updAddress3(value){this.localValue.address3 = value; this.$emit('change', this.localValue.address3); },
    updCity(value){this.localValue.city = value; this.$emit('change', this.localValue.city); },
    updState(value){this.localValue.state = value; this.$emit('change', this.localValue.state); },
    updPostal(value){this.localValue.postal = value; this.$emit('change', this.localValue.postal); },
    updCountry(value){this.localValue.country = value; this.$emit('change', this.localValue.country); },

  }

  

}
</script>
<style scoped>
</style>