const countries = [
  { text: "United States", value: "USA" },
  { text: "Canada", value: "CAN" },
  
  { text: "Afghanistan", value: "AFG" }, 
  { text: "Åland Islands", value: "ALA" },
	{ text: "Albania", value: "ALB" },
	{ text: "Algeria", value: "DZA" },
	{ text: "American Samoa", value: "ASM" },
	{ text: "Andorra", value: "AND" },
	{ text: "Angola", value: "AGO" },
	{ text: "Anguilla", value: "AIA" },
	{ text: "Antarctica", value: "ATA" },
	{ text: "Antigua and Barbuda", value: "ATG" },
	{ text: "Argentina", value: "ARG" },
	{ text: "Armenia", value: "ARM" },
	{ text: "Aruba", value: "ABW" },
	{ text: "Australia", value: "AUS" },
	{ text: "Austria", value: "AUT" },
	{ text: "Azerbaijan", value: "AZE" },
	{ text: "Bahamas", value: "BHS" },
	{ text: "Bahrain", value: "BHR" },
	{ text: "Bangladesh", value: "BGD" },
	{ text: "Barbados", value: "BRB" },
	{ text: "Belarus", value: "BLR" },
	{ text: "Belgium", value: "BEL" },
	{ text: "Belize", value: "BLZ" },
	{ text: "Benin", value: "BEN" },
	{ text: "Bermuda", value: "BMU" },
	{ text: "Bhutan", value: "BTN" },
	{ text: "Bolivia (Plurinational State of)", value: "BOL" },
	{ text: "Bonaire, Sint Eustatius and Saba", value: "BES" },
	{ text: "Bosnia and Herzegovina", value: "BIH" },
	{ text: "Botswana", value: "BWA" },
	{ text: "Bouvet Island", value: "BVT" },
	{ text: "Brazil", value: "BRA" },
	{ text: "British Indian Ocean Territory", value: "IOT" },
	{ text: "Brunei Darussalam", value: "BRN" },
	{ text: "Bulgaria", value: "BGR" },
	{ text: "Burkina Faso", value: "BFA" },
	{ text: "Burundi", value: "BDI" },
	{ text: "Cabo Verde", value: "CPV" },
	{ text: "Cambodia", value: "KHM" },
	{ text: "Cameroon", value: "CMR" },
	{ text: "Cayman Islands", value: "CYM" },
	{ text: "Central African Republic", value: "CAF" },
	{ text: "Chad", value: "TCD" },
	{ text: "Chile", value: "CHL" },
	{ text: "China", value: "CHN" },
	{ text: "Christmas Island", value: "CXR" },
	{ text: "Cocos (Keeling) Islands", value: "CCK" },
	{ text: "Colombia", value: "COL" },
	{ text: "Comoros", value: "COM" },
	{ text: "Congo", value: "COG" },
	{ text: "Congo, Democratic Republic of the", value: "COD" },
	{ text: "Cook Islands", value: "COK" },
	{ text: "Costa Rica", value: "CRI" },
	{ text: "Côte d'Ivoire", value: "CIV" },
	{ text: "Croatia", value: "HRV" },
	{ text: "Cuba", value: "CUB" },
	{ text: "Curaçao", value: "CUW" },
	{ text: "Cyprus", value: "CYP" },
	{ text: "Czechia", value: "CZE" },
	{ text: "Denmark", value: "DNK" },
	{ text: "Djibouti", value: "DJI" },
	{ text: "Dominica", value: "DMA" },
	{ text: "Dominican Republic", value: "DOM" },
	{ text: "Ecuador", value: "ECU" },
	{ text: "Egypt", value: "EGY" },
	{ text: "El Salvador", value: "SLV" },
	{ text: "Equatorial Guinea", value: "GNQ" },
	{ text: "Eritrea", value: "ERI" },
	{ text: "Estonia", value: "EST" },
	{ text: "Eswatini", value: "SWZ" },
	{ text: "Ethiopia", value: "ETH" },
	{ text: "Falkland Islands (Malvinas)", value: "FLK" },
	{ text: "Faroe Islands", value: "FRO" },
	{ text: "Fiji", value: "FJI" },
	{ text: "Finland", value: "FIN" },
	{ text: "France", value: "FRA" },
	{ text: "French Guiana", value: "GUF" },
	{ text: "French Polynesia", value: "PYF" },
	{ text: "French Southern Territories", value: "ATF" },
	{ text: "Gabon", value: "GAB" },
	{ text: "Gambia", value: "GMB" },
	{ text: "Georgia", value: "GEO" },
	{ text: "Germany", value: "DEU" },
	{ text: "Ghana", value: "GHA" },
	{ text: "Gibraltar", value: "GIB" },
	{ text: "Greece", value: "GRC" },
	{ text: "Greenland", value: "GRL" },
	{ text: "Grenada", value: "GRD" },
	{ text: "Guadeloupe", value: "GLP" },
	{ text: "Guam", value: "GUM" },
	{ text: "Guatemala", value: "GTM" },
	{ text: "Guernsey", value: "GGY" },
	{ text: "Guinea", value: "GIN" },
	{ text: "Guinea-Bissau", value: "GNB" },
	{ text: "Guyana", value: "GUY" },
	{ text: "Haiti", value: "HTI" },
	{ text: "Heard Island and McDonald Islands", value: "HMD" },
	{ text: "Holy See", value: "VAT" },
	{ text: "Honduras", value: "HND" },
	{ text: "Hong Kong", value: "HKG" },
	{ text: "Hungary", value: "HUN" },
	{ text: "Iceland", value: "ISL" },
	{ text: "India", value: "IND" },
	{ text: "Indonesia", value: "IDN" },
	{ text: "Iran (Islamic Republic of)", value: "IRN" },
	{ text: "Iraq", value: "IRQ" },
	{ text: "Ireland", value: "IRL" },
	{ text: "Isle of Man", value: "IMN" },
	{ text: "Israel", value: "ISR" },
	{ text: "Italy", value: "ITA" },
	{ text: "Jamaica", value: "JAM" },
	{ text: "Japan", value: "JPN" },
	{ text: "Jersey", value: "JEY" },
	{ text: "Jordan", value: "JOR" },
	{ text: "Kazakhstan", value: "KAZ" },
	{ text: "Kenya", value: "KEN" },
	{ text: "Kiribati", value: "KIR" },
	{ text: "Korea (Democratic People's Republic of)", value: "PRK" },
	{ text: "Korea, Republic of", value: "KOR" },
	{ text: "Kuwait", value: "KWT" },
	{ text: "Kyrgyzstan", value: "KGZ" },
	{ text: "Lao People's Democratic Republic", value: "LAO" },
	{ text: "Latvia", value: "LVA" },
	{ text: "Lebanon", value: "LBN" },
	{ text: "Lesotho", value: "LSO" },
	{ text: "Liberia", value: "LBR" },
	{ text: "Libya", value: "LBY" },
	{ text: "Liechtenstein", value: "LIE" },
	{ text: "Lithuania", value: "LTU" },
	{ text: "Luxembourg", value: "LUX" },
	{ text: "Macao", value: "MAC" },
	{ text: "Madagascar", value: "MDG" },
	{ text: "Malawi", value: "MWI" },
	{ text: "Malaysia", value: "MYS" },
	{ text: "Maldives", value: "MDV" },
	{ text: "Mali", value: "MLI" },
	{ text: "Malta", value: "MLT" },
	{ text: "Marshall Islands", value: "MHL" },
	{ text: "Martinique", value: "MTQ" },
	{ text: "Mauritania", value: "MRT" },
	{ text: "Mauritius", value: "MUS" },
	{ text: "Mayotte", value: "MYT" },
	{ text: "Mexico", value: "MEX" },
	{ text: "Micronesia (Federated States of)", value: "FSM" },
	{ text: "Moldova, Republic of", value: "MDA" },
	{ text: "Monaco", value: "MCO" },
	{ text: "Mongolia", value: "MNG" },
	{ text: "Montenegro", value: "MNE" },
	{ text: "Montserrat", value: "MSR" },
	{ text: "Morocco", value: "MAR" },
	{ text: "Mozambique", value: "MOZ" },
	{ text: "Myanmar", value: "MMR" },
	{ text: "Namibia", value: "NAM" },
	{ text: "Nauru", value: "NRU" },
	{ text: "Nepal", value: "NPL" },
	{ text: "Netherlands", value: "NLD" },
	{ text: "New Caledonia", value: "NCL" },
	{ text: "New Zealand", value: "NZL" },
	{ text: "Nicaragua", value: "NIC" },
	{ text: "Niger", value: "NER" },
	{ text: "Nigeria", value: "NGA" },
	{ text: "Niue", value: "NIU" },
	{ text: "Norfolk Island", value: "NFK" },
	{ text: "North Macedonia", value: "MKD" },
	{ text: "Northern Mariana Islands", value: "MNP" },
	{ text: "Norway", value: "NOR" },
	{ text: "Oman", value: "OMN" },
	{ text: "Pakistan", value: "PAK" },
	{ text: "Palau", value: "PLW" },
	{ text: "Palestine, State of", value: "PSE" },
	{ text: "Panama", value: "PAN" },
	{ text: "Papua New Guinea", value: "PNG" },
	{ text: "Paraguay", value: "PRY" },
	{ text: "Peru", value: "PER" },
	{ text: "Philippines", value: "PHL" },
	{ text: "Pitcairn", value: "PCN" },
	{ text: "Poland", value: "POL" },
	{ text: "Portugal", value: "PRT" },
	{ text: "Puerto Rico", value: "PRI" },
	{ text: "Qatar", value: "QAT" },
	{ text: "Réunion", value: "REU" },
	{ text: "Romania", value: "ROU" },
	{ text: "Russian Federation", value: "RUS" },
	{ text: "Rwanda", value: "RWA" },
	{ text: "Saint Barthélemy", value: "BLM" },
	{ text: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
	{ text: "Saint Kitts and Nevis", value: "KNA" },
	{ text: "Saint Lucia", value: "LCA" },
	{ text: "Saint Martin (French part)", value: "MAF" },
	{ text: "Saint Pierre and Miquelon", value: "SPM" },
	{ text: "Saint Vincent and the Grenadines", value: "VCT" },
	{ text: "Samoa", value: "WSM" },
	{ text: "San Marino", value: "SMR" },
	{ text: "Sao Tome and Principe", value: "STP" },
	{ text: "Saudi Arabia", value: "SAU" },
	{ text: "Senegal", value: "SEN" },
	{ text: "Serbia", value: "SRB" },
	{ text: "Seychelles", value: "SYC" },
	{ text: "Sierra Leone", value: "SLE" },
	{ text: "Singapore", value: "SGP" },
	{ text: "Sint Maarten (Dutch part)", value: "SXM" },
	{ text: "Slovakia", value: "SVK" },
	{ text: "Slovenia", value: "SVN" },
	{ text: "Solomon Islands", value: "SLB" },
	{ text: "Somalia", value: "SOM" },
	{ text: "South Africa", value: "ZAF" },
	{ text: "South Georgia and the South Sandwich Islands", value: "SGS" },
	{ text: "South Sudan", value: "SSD" },
	{ text: "Spain", value: "ESP" },
	{ text: "Sri Lanka", value: "LKA" },
	{ text: "Sudan", value: "SDN" },
	{ text: "Suriname", value: "SUR" },
	{ text: "Svalbard and Jan Mayen", value: "SJM" },
	{ text: "Sweden", value: "SWE" },
	{ text: "Switzerland", value: "CHE" },
	{ text: "Syrian Arab Republic", value: "SYR" },
	{ text: "Taiwan, Province of China", value: "TWN" },
	{ text: "Tajikistan", value: "TJK" },
	{ text: "Tanzania, United Republic of", value: "TZA" },
	{ text: "Thailand", value: "THA" },
	{ text: "Timor-Leste", value: "TLS" },
	{ text: "Togo", value: "TGO" },
	{ text: "Tokelau", value: "TKL" },
	{ text: "Tonga", value: "TON" },
	{ text: "Trinidad and Tobago", value: "TTO" },
	{ text: "Tunisia", value: "TUN" },
	{ text: "Turkey", value: "TUR" },
	{ text: "Turkmenistan", value: "TKM" },
	{ text: "Turks and Caicos Islands", value: "TCA" },
	{ text: "Tuvalu", value: "TUV" },
	{ text: "Uganda", value: "UGA" },
	{ text: "Ukraine", value: "UKR" },
	{ text: "United Arab Emirates", value: "ARE" },
	{ text: "United Kingdom of Great Britain and Northern Ireland", value: "GBR" },
	{ text: "United States Minor Outlying Islands", value: "UMI" },
	{ text: "Uruguay", value: "URY" },
	{ text: "Uzbekistan", value: "UZB" },
	{ text: "Vanuatu", value: "VUT" },
	{ text: "Venezuela (Bolivarian Republic of)", value: "VEN" },
	{ text: "Viet Nam", value: "VNM" },
	{ text: "Virgin Islands (British)", value: "VGB" },
	{ text: "Virgin Islands (U.S.)", value: "VIR" },
	{ text: "Wallis and Futuna", value: "WLF" },
	{ text: "Western Sahara", value: "ESH" },
	{ text: "Yemen", value: "YEM" },
	{ text: "Zambia", value: "ZMB" },
	{ text: "Zimbabwe", value: "ZWE" }
];
const usa_states = [
  { "text": "Alabama", "value": "AL" },
  { "text": "Alaska", "value": "AK" },
  { "text": "American Samoa", "value": "AS" },
  { "text": "Arizona", "value": "AZ" },
  { "text": "Arkansas", "value": "AR" },
  { "text": "California", "value": "CA" },
  { "text": "Colorado", "value": "CO" },
  { "text": "Connecticut", "value": "CT" },
  { "text": "Delaware", "value": "DE" },
  { "text": "District Of Columbia", "value": "DC" },
  { "text": "Federated States Of Micronesia", "value": "FM" },
  { "text": "Florida", "value": "FL" },
  { "text": "Georgia", "value": "GA" },
  { "text": "Guam", "value": "GU" },
  { "text": "Hawaii", "value": "HI" },
  { "text": "Idaho", "value": "ID" },
  { "text": "Illinois", "value": "IL" },
  { "text": "Indiana", "value": "IN" },
  { "text": "Iowa", "value": "IA" },
  { "text": "Kansas", "value": "KS" },
  { "text": "Kentucky", "value": "KY" },
  { "text": "Louisiana", "value": "LA" },
  { "text": "Maine", "value": "ME" },
  { "text": "Marshall Islands", "value": "MH" },
  { "text": "Maryland", "value": "MD" },
  { "text": "Massachusetts", "value": "MA" },
  { "text": "Michigan", "value": "MI" },
  { "text": "Minnesota", "value": "MN" },
  { "text": "Mississippi", "value": "MS" },
  { "text": "Missouri", "value": "MO" },
  { "text": "Montana", "value": "MT" },
  { "text": "Nebraska", "value": "NE" },
  { "text": "Nevada", "value": "NV" },
  { "text": "New Hampshire", "value": "NH" },
  { "text": "New Jersey", "value": "NJ" },
  { "text": "New Mexico", "value": "NM" },
  { "text": "New York", "value": "NY" },
  { "text": "North Carolina", "value": "NC" },
  { "text": "North Dakota", "value": "ND" },
  { "text": "Northern Mariana Islands", "value": "MP" },
  { "text": "Ohio", "value": "OH" },
  { "text": "Oklahoma", "value": "OK" },
  { "text": "Oregon", "value": "OR" },
  { "text": "Palau", "value": "PW" },
  { "text": "Pennsylvania", "value": "PA" },
  { "text": "Puerto Rico", "value": "PR" },
  { "text": "Rhode Island", "value": "RI" },
  { "text": "South Carolina", "value": "SC" },
  { "text": "South Dakota", "value": "SD" },
  { "text": "Tennessee", "value": "TN" },
  { "text": "Texas", "value": "TX" },
  { "text": "Utah", "value": "UT" },
  { "text": "Vermont", "value": "VT" },
  { "text": "Virgin Islands", "value": "VI" },
  { "text": "Virginia", "value": "VA" },
  { "text": "Washington", "value": "WA" },
  { "text": "West Virginia", "value": "WV" },
  { "text": "Wisconsin", "value": "WI" },
  { "text": "Wyoming", "value": "WY" }
];
const can_provinces = [
  { "value": "AB", "text": "Alberta" },
  { "value": "BC", "text": "British Columbia" },
  { "value": "MB", "text": "Manitoba" },
  { "value": "NB", "text": "New Brunswick" },
  { "value": "NL", "text": "Newfoundland and Labrador" },
  { "value": "NS", "text": "Nova Scotia" },
  { "value": "NT", "text": "Northwest Territories" },
  { "value": "NU", "text": "Nunavut" },
  { "value": "ON", "text": "Ontario" },
  { "value": "PE", "text": "Prince Edward Island" },
  { "value": "QC", "text": "Québec" },
  { "value": "SK", "text": "Saskatchewan" },
  { "value": "YT", "text": "Yukon" }
];

export { countries, usa_states, can_provinces };