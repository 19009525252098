<template lang="pug">
b-container(fluid).bg-light
  .d-flex.justify-content-between.p-1.m-0
    b-button.mb-1(v-b-toggle.collapse-filters) 
      b-icon-filter
      span.ml-1 Filters
    span
      b-spinner.mt-5(variant="info" v-if="busy")
    b-button-group.p-0
      b-button.mb-1(@click="clearCriteria") Clear
      b-button.mb-1(variant="primary"  @click="doSearch") 
        b-icon-search
        span.ml-1 Search
  .d-flex.justify-content-center( v-if="!busy && !has_error" ) 
    span.text-info {{ programs.length }} match{{ programs.length > 1 ? 'es': ''}} found.
  
  b-collapse(show)#collapse-filters
    b-form
      b-form-group(label-size="sm" label="Status:" label-cols="3" v-if="is_admin")
        b-form-select(size="sm" v-model="filter.status" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(value="private") Private
          b-form-select-option(value="published") Published
      
      b-form-group(label-size="sm" label-cols="3" )
        template(#label)
          b-icon-search
        b-form-input(size="sm" v-model="filter.search_term" @change="doSearch" )
      
      b-form-group(label-size="sm" label="Location:" label-cols="3")
        b-form-select(size="sm" v-model="filter.geo" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(v-for="opt, idx of region_and_state_options" :key="idx" :value="opt.value") {{opt.text}}

      b-form-group(label-size="sm" label="Participating in AAMC ERAS Preference Signaling:" label-cols="9" label-class="callout")
        b-form-select(size="sm" v-model="filter.aamc_eras_supp_application" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(:value="Boolean(true)") Yes
          b-form-select-option(:value="Boolean(false)") No

      b-form-group(label-size="sm" label="Participating in Universal Offer Day:" label-cols="9" label-class="callout")
        b-form-select(size="sm" v-model="filter.offer_day_aoa_cord_uod" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(:value="Boolean(true)") Yes
          b-form-select-option(:value="Boolean(false)") No

      b-form-group(label-size="sm" label="Virtual Interviews Available?" label-cols="9" label-class="callout")
        b-form-select(size="sm" v-model="filter.virtual_interviews" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(value="yes") Yes
          b-form-select-option(value="no") No

      b-form-group(label-size="sm" label="In-Person Interviews Available?" label-cols="9" label-class="callout")
        b-form-select(size="sm" v-model="filter.in_person_interviews" @change="doSearch" )
          template(#first)
            b-form-select-option(:value="null" ) -- Any --
          b-form-select-option(value="yes") Yes
          b-form-select-option(value="no") No


      //- SearchRangeField(ref="step1_median_slider" :filter="filter" 
      //-   filter_property="step1_median" 
      //-   filter_property_max="step1_median_max" 
      //-   :min="192" :max="300" 
      //-   label="Median USMLE Step 1 (of Current Residents):"
      //-   @update-range="rangeSearch" )

      SearchRangeField(ref="step2_median_slider" :filter="filter" 
        filter_property="step2_median" 
        filter_property_max="step2_median_max" 
        :min="192" :max="300" 
        label="Median USMLE Step 2 (of Current Residents):" 
        @update-range="rangeSearch" )
      
      SearchRangeField(ref="comlex_usa_level1_median_slider" :filter="filter" 
        filter_property="comlex_usa_level1_median" 
        filter_property_max="comlex_usa_level1_median_max" 
        :min="9" :max="999" 
        label="Median COMLEX-USA Level 1 (of Current Residents):" 
        @update-range="rangeSearch" )

      SearchRangeField(ref="comlex_usa_level2_median_slider" :filter="filter" 
        filter_property="comlex_usa_level2_median" 
        filter_property_max="comlex_usa_level2_median_max" 
        :min="9" :max="999" 
        label="Median COMLEX-USA Level 2 (of Current Residents):" 
        @update-range="rangeSearch" )

      SearchRangeField(ref="residents_total_slider" :filter="filter" 
        filter_property="residents_total"
        filter_property_max="residents_total_max" 
        :min="1" :max="50" 
        label="Number of Residents:" 
        @update-range="rangeSearch" )
      
      SearchRangeField(ref="residents_minority_slider" :filter="filter" 
        filter_property="residents_minority" 
        filter_property_max="residents_minority_max" 
        :min="0" :max="100" 
        label="% URiM Residents:" 
        @update-range="rangeSearch" )
      
      SearchRangeField(ref="residents_female_slider" :filter="filter" 
        filter_property="residents_female" 
        filter_property_max="residents_female_max" 
        :min="0" :max="100" 
        label="% Female Residents:" 
        @update-range="rangeSearch" )

      b-form-group(label-size="sm" label="Length of Training:" label-cols="5")
        b-form-select(size="sm" v-model="filter.length_of_training" :options="length_of_training_options" @change="doSearch" )

  b-container.bg-white.outline-primary#search-results(fluid)
    div.text-secondary( v-if="programs.length===0 && !busy && !has_error")
      .p-3
        p.
          Browse for matching residency programs by entering your filter criteria above. 
          Clicking "Search" with no filters added will return a complete list of residency programs that are members of the AOA/CORD program.

        p.
          If a general search does not display a specific residency program in which you are interested, 
          the program may have either elected to withdraw their participation in ORIN &reg; or they are still entering data and have not yet published their profile. 
          Residency programs are responsible for entering their own data; therefore, contacting the program directly for more information is recommended.

    ProgramSearchResultMobile( v-for="program of programs" :key="program.id" :program="program")
</template>

<script>
import Base from '@/components/Base.vue'
import ProgramSearchResultMobile from '@/components/ProgramSearchResultMobile.vue'
import SearchRangeField from '@/components/SearchRangeField.vue'
import StatusWidget from '@/components/StatusWidget.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import baseMix from '@/mixins/base'
import which_role from '@/mixins/which-role'
import { usa_states, can_provinces } from '@/mixins/geo'

export default {
  name: 'MobileSearch',

  mixins: [ baseMix, which_role ],
  
  components: {
    Base,
    ProgramSearchResultMobile,
    SearchRangeField,
    StatusWidget,
  },
  
  props: {},

  data(){
    return {
      filter:{
        // text search term
        search_term: "",

        // numbers
        comlex_usa_level1_median: null,
        comlex_usa_level1_median_max: null,
        comlex_usa_level2_median: null,
        comlex_usa_level2_median_max: null,
        step1_median: null,
        step1_median_max: null,
        step2_median: null,
        step2_median_max: null,
        residents_total: null,

        // percentages
        residents_minority: null,
        residents_female: null,
        
        // discreet values
        state: null,
        length_of_training: null,
        region: null,
        geo: null,
        status: 'published',
        offer_day_aoa_cord_uod: null,
        aamc_eras_supp_application: null,
        virtual_interviews: null,
        in_person_interviews: null,
      },
      // program_fields:[
      //   { key: 'name_and_location' },
      //   { key: 'residents_total' },
      //   { key: 'length_of_training' },
      //   { key: 'weeks_dedicated_research_time' },
      //   { key: 'trauma_level' },
      //   { key: 'weeks_dedicated_research_time' },
      //   { key: 'residents_female' },
      //   { key: 'residents_minority' },
      //   { key: 'residents_elective_rotation' },
      //   { key: 'step1_median' },
      //   { key: 'step2_median' },
      // ],
      resultHovered: false,
    };
  },

  computed:{
    ...mapGetters(['geography','programs']),
    states_and_provinces(){
      let options = [];
      options.push({text:'(United States)', value:null});
      options = options.concat(usa_states);
      // options.push({text:'(Canada)', disabled: true});
      // options = options.concat(can_provinces);
      return options
    },
    region_options(){
      let options = [];
      options = this.geography.geo_groups.filter(gg=>gg.category==='region').map(r=>{return {text: r.name, value: r.geos.map(g=>g.state).join(',')}; });
      return options;
    },
    region_and_state_options(){
      let options = [];
      if(this.geography){
        this.geography.geo_groups.filter(gg=>gg.category==='region').forEach(r=>{
          if(r.name==='International') return;
          options.push({
            text: r.name, 
            value: r.geos.map(g=>g.state).join(','),
          });

          r.geos.forEach(g=>{
            let st = null;
            if(g.country==='USA'){
              st = usa_states.find(st=>st.value===g.state);
            } else if (g.country==='CAN'){
              // st = can_provinces.find(st=>st.value===g.state);
            }

            if(st){
              options.push({
                text: ` ... ${st ? st.text : g.state}`, //indent using elipses
                value: g.state,
              });
            }
            
          });
          
        });
      }
      return options;
    },
    length_of_training_options(){
      return [
        {text:'Any', value: null},
        {text:'5 years', value:'5 years'},
        {text:'6 years', value:'6 years'}
      ];
    }
  },

  methods: {
    ...mapActions(['searchForPrograms']),
    ...mapMutations(['setPrograms','setError','clearMessageAndErrors']),
    async rangeSearch(rangePayload){
      rangePayload.forEach(nvp=>{
        this.filter[nvp.name] = nvp.value;
      })
      await this.doSearch();
    },
    async doSearch(){
      // if(this.filter.step1_median && this.filter.step1_median_max ){
      //   let min = Number(this.filter.step1_median);
      //   let max = Number(this.filter.step1_median_max);
      //   if(min>max){
      //     this.setError('Please enter a valid Median USMLE Step 1 search. The min search value must be less than the max search value.');
      //     return;
      //   }
      // }
      if(this.filter.step2_median && this.filter.step2_median_max ){
        let min = Number(this.filter.step2_median);
        let max = Number(this.filter.step2_median_max);
        if(min>max){
          this.setError('Please enter a valid Median USMLE Step 2 search. The min search value must be less than the max search value.');
          return;
        }
      }
      if(this.filter.comlex_usa_level1_median && this.filter.comlex_usa_level1_median_max ){
        let min = Number(this.filter.comlex_usa_level1_median);
        let max = Number(this.filter.comlex_usa_level1_median_max);
        if(min>max){
          this.setError('Please enter a valid Median COMLEX-USA Level 1 search. The min search value must be less than the max search value.');
          return;
        }
      }
      if(this.filter.comlex_usa_level2_median && this.filter.comlex_usa_level2_median_max ){
        let min = Number(this.filter.comlex_usa_level2_median);
        let max = Number(this.filter.comlex_usa_level2_median_max);
        if(min>max){
          this.setError('Please enter a valid Median COMLEX-USA Level 2 search. The min search value must be less than the max search value.');
          return;
        }
      }
      this.searchForPrograms({search: this.filter});
    },

    clearCriteria(){
      for(let k in this.filter){
        this.$set(this.filter, k, null);
      }
      this.$refs.comlex_usa_level1_median_slider.reset();
      this.$refs.comlex_usa_level2_median_slider.reset();
      this.$refs.step1_median_slider.reset();
      this.$refs.step2_median_slider.reset();
      this.$refs.residents_total_slider.reset();
      this.$refs.residents_minority_slider.reset();
      this.$refs.residents_female_slider.reset();

      this.filter.status='published';
      this.filter.search_term='';
      this.filter.offer_day_aoa_cord_uod=null;
      this.filter.aamc_eras_supp_application=null;
      this.setPrograms([]);
      this.clearMessageAndErrors();
    },

    hovered(hover_engaged){
      this.resultHovered = hover_engaged;
    }
  },
}
</script>

<style>
.callout{
  font-weight: bolder;
  color: #794039;
}
</style>