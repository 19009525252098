<template lang="pug">
b-container(fluid)
  .row
    //- Condition-Building Column
    .col-4.col-4-md.m-0.bg-light(style="min-height: 80vh;")
      div.d-flex.justify-content-between
        span.h5.text-primary Filters
        b-button-group
          b-button.mb-1(size="sm" @click="clearCriteria") Clear
          b-button.mb-1(size="sm" variant="primary"  @click="doSearch" :disabled="busy || !search_condition") Search
      div.d-flex.justify-content-between
        b-button(@click="addGroup" size="sm" title="add group" :disabled="busy")
          b-icon-plus(size="sm") 
          span Add Group
      div(v-for="(group, idx) in query_groups" :key="idx")
        QueryGroup( :index="idx" :aliases="sorted_aliases" :logical="group.logical" :conditions="group.conditions" @changed="observeChanges" @removed="removeGroup")

    //- Results Column
    .col.bg-white.p-0
      .text-secondary( v-if="!results && !busy && !has_error")
        .p-3
          p.
            Browse for matching residency programs by entering your filter criteria on the left. 
          
      StatusWidget
      b-container(v-if="results")
        b-badge( variant="info" ) {{results.length}} matches
        a( :href="toCsvStream" download="results.csv")
          b-badge( variant="success" ) download
        b-table( small striped :items="results" :fields="result_fields" )
</template>

<script>
import _ from 'lodash'
import Base from '@/components/Base.vue'
import QueryGroup from '@/components/QueryGroup.vue'
import StatusWidget from '@/components/StatusWidget.vue'
import {mapGetters, mapActions} from 'vuex'
import baseMix from '@/mixins/base'
import which_role from '@/mixins/which-role'

export default {
  name: 'QueryBrowser',

  mixins: [ baseMix, which_role ],
  
  components: {
    Base,
    QueryGroup,
    StatusWidget,
  },
  
  props: {},

  data(){
    return {
      query_groups: [],
      results: null,// array, but init as null
      search_condition: null,
    };
  },

  computed:{
    ...mapGetters(['aliases','geography','programs']),
    result_fields(){
      if(!this.results || this.results.length === 0) return [];
      let r = this.results[0];
      let field_defs = [];
      for(let key in r){
        if(key!='id'){
          field_defs.push( {key, sortable: true} );
        }
      }
      return field_defs;
    },
    sorted_aliases(){
      return this.aliases ? this.aliases.filter(a=>a.searchable).sort((a,b) => {
        // prefer defined order over all else
        let aNum = Number(a.order);
        let bNum = Number(b.order);
        if(!isFinite(aNum)) aNum=9999;
        if(!isFinite(bNum)) bNum=9999;
        return aNum-bNum || a.alias.localeCompare(b.alias);
      }): [];
    },
    toCsvStream(){
      if(!this.results || this.results.length === 0) return "";
      let s = `data:application/octet-stream,`;
      //headers 
      let row1 = this.results[0];
      let headers = [];
      for(let key in row1){
        s+= `${encodeURIComponent(key+',')}`;
        headers.push(key);
      }
      s += encodeURIComponent('\n');
      //data
      for(let row of this.results){
        for(let header of headers){
          let v = row[header];
          if(typeof v === 'undefined' || v === null) v= '';
          s+= `${encodeURIComponent(v+',')}`;
        }
        s += encodeURIComponent('\n');
      }
      return s;
    }
  },

  methods: {
    ...mapActions(['apiPost']),
    // ...mapMutations([]),
    addGroup(){
      this.query_groups.push( { logical: 'AND', conditions: [ 
        //must be consistent with addConditionExpression in QueryGroup
        {
          index: 0,
          logical: 'AND', 
          alias: '', 
          operator:'=', 
          value: null
        }
      ]} );
    },
    clearCriteria(){
      this.query_groups = [];
      this.results = null;
      this.search_condition = null;
    },
    async doSearch(){
      this.results = await this.apiPost({url: `/api/v1/qbrowser/query`, body: this.query_groups});
    },
    observeChanges(group){
      console.debug(`Modifying group ${group.index} ===> \n${JSON.stringify(group, null, 2)}`);
      this.query_groups.splice(group.index, 1, group);
      this.doSearch();
    },
    removeGroup(index){
      console.debug(`Removing group with index ${index} inside the list of ${this.query_groups.length}`)
      let actualArrayIndex = this.query_groups.findIndex(g=>g.index==index);

      this.query_groups.splice(actualArrayIndex, 1);

      if(this.query_groups.length > 0){ 
        this.doSearch();
      } else {
        this.clearCriteria();
      }
    },
    
    
  },
}

</script>

<style>
.callout{
  font-weight: bolder;
  color: #794039;
}
</style>