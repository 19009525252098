<template lang="pug">
  Base(title="Manage Metrics")
    b-container(fluid v-if="orgs")
      p.
        Metrics are values that can be stored for a residency program. An example of a simple metric could be a `count of residents`
      b-table.mt-2(striped hover  :busy="busy" :fields="table_fields" :items="metrics" @row-clicked="toOrg" )
</template>

<script>
import Base from '@/components/Base.vue'
import {mapGetters, mapActions} from 'vuex'
import baseMix from '@/mixins/base'

export default {
  name: 'ManageOrgs',

  mixins: [baseMix],
  
  components: {
    Base,
  },
  
  computed: {
    ...mapGetters(['orgs','org'])
  },

  data(){
    return {
      table_fields:[
        { key: 'id', sortable: false, },
        { key: 'name', sortable: true, },
        { key: 'updated', sortable: true, }
      ]
    };
  },

  created(){
    this.load();
  },

  methods: {
    ...mapActions(['searchOrgs','loadOrg','loadPrograms']),
    load(){
      this.searchOrgs();
    },
    toOrg(item){
      this.$router.push(`/orgs/${item.id}`);
    },
  },
}
</script>
